import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import constants from 'src/constant.json';
import { UserState } from '../../state/user.state';
interface shareEvent {
  copyUrl: any;
  proceedAction?: any;
  shareUrl: string;
  shareStatus: boolean;
  enableSocialShare: boolean;
  enableCopyLink: boolean;
  dealData: any;
}

@Component({
  selector: 'app-link-share',
  templateUrl: './link-share.component.html',
  styleUrls: ['./link-share.component.scss'],
})
export class LinkShareComponent implements OnInit {
  @ViewChild('inviteUrlField') inviteUrlField: ElementRef;
  propertyImages: any;
  userName: string;
  userEmail: string;
  copyBtnText = 'copy';
  userDetailSub$;
  sharePropertyForm: FormGroup;
  shareUrl: string;
  sendData: any;
  enableButton: boolean = true;
  onDestroyNotifier$ = new Subject();
  sentData: any;
  enableCopyLink: boolean = true;
  enableSocialShare: boolean = false;
  shareStatus: boolean;
  dealDetails: boolean;
  copyUrl: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: shareEvent,
    public dialogRef: MatDialogRef<LinkShareComponent>,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public appState: AppState,
    private userState: UserState
  ) { }

  ngOnInit(): void {
    this.dealDetails = this.data.shareStatus;
    this.enableSocialShare = this.data.enableSocialShare;
    this.shareUrl = this.data.shareUrl;
    this.copyUrl = this.data.copyUrl;
    this.enableCopyLink = this.data.enableCopyLink;
    this.userDetailSub$ = this.userState.userDetail$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res: any) => {
      this.userName = res?.user_profile?.firstName;
      this.userEmail = res?.user_profile?.email;
    });

    this.sharePropertyForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
      email: ['', [Validators.required, Validators.pattern(constants.regex.validEmail)]],
      recipientName: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
      recipientEmail: ['', [Validators.required, Validators.pattern(constants.regex.validEmail)]],
    });
  }
  get sharePropertyFormControls() {
    return this.sharePropertyForm.controls;
  }
  close() {
    this.dialogRef.close(true);
  }

  sendUserDetails() {
    if (this.sharePropertyForm.invalid) {
      if (
        this.sharePropertyForm.controls.recipientName.status == 'INVALID' ||
        this.sharePropertyForm.controls.recipientEmail.status == 'INVALID'
      ) {
        return;
      } else if (!this.appState.authTokenValue?.idToken) {
        if (
          this.sharePropertyForm.controls.name.status == 'INVALID' ||
          this.sharePropertyForm.controls.email.status == 'INVALID'
        ) {
          return;
        }
      }
    }
    this.enableButton = false;
    this.sentData = {
      recipientEmail: this.sharePropertyForm.value.recipientEmail.toLowerCase(),
      recipientName: this.sharePropertyForm.value.recipientName,
      senderName: this.appState.authTokenValue?.idToken ? this.userName : this.sharePropertyForm.value.name,
      senderEmail: this.appState.authTokenValue?.idToken
        ? this.userEmail.toLowerCase()
        : this.sharePropertyForm.value.email.toLowerCase(),
    };
    this.data.proceedAction(this.sentData, () => { });
    return this.sentData;
  }
  openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }
  ngOnDestroy() {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }
}
