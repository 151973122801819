<!-- <div #streetView style="width: 100%; height: 400px" [hidden]="isError"></div> -->
<div #streetView style="width: 100%" [style.height.px]="isMyHomeAccess ? 120 : 400" [hidden]="isError"></div>
<div
  #streetViewError
  style="width: 100%; height: 400px; background: #101010"
  class="validateError text-white text-center flex_center"
  [hidden]="!isError"
>
  <div>
    <img src="../../../assets/images/streetView.svg" width="70" class="img-fluid mb-1" />
    <h6>
      Street view <br />
      data not found for this location.
    </h6>
  </div>
</div>
