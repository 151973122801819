import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DealAnalysisConfig } from 'src/app/_shared/config/deal-analysis.config';
import {
  expensePieChartCategories,
  incomePieChartCategories,
  lineChartCategories,
} from 'src/app/_shared/interface/deal-analysis.interface';
import { AppState } from 'src/app/app.state';
import { MyHomeState } from 'src/app/my-home-page/state/my-home.state';
import { PropertyState } from 'src/app/workspace/state/property.state';
import { DealComputationInput, DealComputedResult } from '../../interface/deal-analysis.interface';
import { DealAnalysisService } from '../../service/deal-analysis.service';
import { DealAnalysisState } from '../../state/deal-analysis.state';

@Injectable({
  providedIn: 'root',
})
export class DealAnalysisSheetFacade {
  defaultAnalysisResult = {
    revenue: [],
    expense: [],
    cashFlow: [],
    taxDeduction: [],
    equityAppreciation: [],
    salePrediction: [],
    returnIndicator: [],
  };
  defaultYearList = [1, 2, 3, 4, 5, 10, 15, 20, 30];
  displayYearList$ = new BehaviorSubject<Array<number>>(this.defaultYearList);
  displayAnalysisResult$ = new BehaviorSubject<any>(this.defaultAnalysisResult);
  displayAnalysisResults$ = new BehaviorSubject<any>(this.defaultAnalysisResult);

  constructor(
    private dealAnalysisState: DealAnalysisState,
    private dealAnalysisService: DealAnalysisService,
    private dealAnalysisConfig: DealAnalysisConfig,
    private propertyState: PropertyState,
    public appState: AppState,
    private MyHomeState: MyHomeState
  ) {
    this.initSubscription();
  }

  initSubscription() {
    this.dealAnalysisState.dealAnalysisResult.subscribe((res: DealComputedResult) => {
      this.filterDisplayResult(res, this.displayYearList$.value);
    });
    this.displayYearList$.subscribe((res) => {
      this.filterDisplayResult(this.dealAnalysisState.dealAnalysisResultValue, res);
    });
    this.dealAnalysisState.dealAnalysisResult.subscribe((res: DealComputedResult) => {

      this.convertData(res);
    });

  }

  getDealAnalysisFormInput(): Observable<any> {
    return this.dealAnalysisState.$dealAnalysisFormInput;
  }

  getActivePropertyDetails$(): Observable<any> {
    return this.propertyState.activePropertyDetails$;
  }

  getActiveProperty$(): Observable<any> {
    return this.propertyState.activeProperty$;
  }
  setAnalyseDealParams(dealAnalysisValues, navigatedFromMyPropertyPage, mortgageStartYear) {

    let analyseDealValues: DealComputationInput;
    analyseDealValues = {
      uPurchasePrice: dealAnalysisValues.uPurchasePrice,
      uPropertyCurrentValue: dealAnalysisValues.uPropertyCurrentValue,
      uDownPayment: dealAnalysisValues.uDownPayment ? dealAnalysisValues.uDownPayment : null,
      uDownPaymentRatio: dealAnalysisValues.uDownPayment ? dealAnalysisValues.uDownPayment * 0.01 : null,
      uLandValue: dealAnalysisValues.uLandValue,
      uClosingCost: dealAnalysisValues.uClosingCost,
      uRenovationCost: dealAnalysisValues.uRenovationCost,
      uLoanAmount: dealAnalysisValues.uLoanAmount,
      uInterestRate: dealAnalysisValues.uInterestRate * 0.01,
      uLoanTerm: dealAnalysisValues.uLoanTerm,
      uPropertyTaxAmount: dealAnalysisValues.uPropertyTaxAmount,
      uInsuranceAmount: dealAnalysisValues.uInsuranceAmount,
      uHoaFee: dealAnalysisValues.uHoaFee,
      uOtherExpenses: dealAnalysisValues.uOtherExpenses * 0.01,
      uHoaFeeYoYRatio: dealAnalysisValues.uHoaFeeYoYPercent * 0.01,
      uPropertyManagementRatio: dealAnalysisValues.uPropertyManagementPercent * 0.01,
      uMaintenanceRatio: dealAnalysisValues.uMaintenancePercent * 0.01,
      uImprovementRatio: dealAnalysisValues.uImprovementPercent * 0.01,
      uWarrantyServiceCost: dealAnalysisValues.uWarrantyServiceCost,
      uWarrantyServiceYoYRatio: dealAnalysisValues.uWarrantyServiceYoYPercent * 0.01,
      uAccountingCost: dealAnalysisValues.uAccountingCost,
      uAccountingYoYRatio: dealAnalysisValues.uAccountingYoYPercent * 0.01,
      uMonthlyRent: dealAnalysisValues.uMonthlyRent,

      uMonthlyRentYoYRatio: dealAnalysisValues.uMonthlyRentYoYPercent * 0.01,
      uRentChurnRatio: dealAnalysisValues.uRenterChurn * 0.01,
      uTenantSearchDuration: dealAnalysisValues.uTenantSearchDuration,
      uAssessedPropertyValue: dealAnalysisValues.uAssessedPropertyValue,
      uAssessedPropertyValueYoYRatio: dealAnalysisValues.uAssessedPropertyValueYoYPercent * 0.01,
      uFairMarketValue: dealAnalysisValues.uFairMarketValue,
      uFairMarketValueYoYRatio: dealAnalysisValues.uFairMarketValueYoYPercent * 0.01,
      // uSalesCommissionRatio: dealAnalysisValues.uSalesCommissionPercent * 0.01,
      uSalesCommissionRatio:
        (dealAnalysisValues.uSalesBuyerAgentCommissionPercent + dealAnalysisValues.uSalesSellerAgentCommissionPercent) *
        0.01,
      uState: dealAnalysisValues.uState ? dealAnalysisValues.uState.toLowerCase() : 'nm',
      uYear: null,

    };
    let term = 30;
    let mortgageData;
    if (navigatedFromMyPropertyPage && mortgageStartYear) {
      term = dealAnalysisValues?.uLoanTerm;
      mortgageData = {
        navigatedFromMyPropertyPage: navigatedFromMyPropertyPage,
        mortgageStartingYear: mortgageStartYear
      }

    }

    this.dealAnalysisState.dealAnalysisResultValue = this.dealAnalysisService.analyseDeal(analyseDealValues, term, mortgageData);
  }

  isSheetOpen(): Observable<any> {
    return this.dealAnalysisState.$openDealAnalysisSheet;
  }

  closeDealAnalysisSheet() {
    this.dealAnalysisState.openDealAnalysisSheetValue = false;
    this.dealAnalysisState.isSavedDealActiveValue = false;
    this.dealAnalysisState.dealAnalysisResultValue = null;
    // this.displayAnalysisResult$.next(this.defaultAnalysisResult);
    // this.workspaceState.openPropertyDetailsSheetValue = false
  }

  getDealAnalysisData(): Observable<any> {
    return this.dealAnalysisState.dealAnalysisResult;
  }

  get expensePieChartData() {
    return this.dealAnalysisConfig.expensePieChart;
  }

  get salePredictionLineChart() {
    return this.dealAnalysisConfig.salePredictionLineChart;
  }

  get returnIndicatorLineChart() {
    return this.dealAnalysisConfig.returnIndicatorLineChart;
  }

  get incomePieChartData() {
    return this.dealAnalysisConfig.incomePieChart;
  }

  get salePredictionSettings() {
    return this.dealAnalysisConfig.salePredictionSettings;
  }

  get returnIndicatorSettings() {
    return this.dealAnalysisConfig.returnIndicatorSettings;
  }

  get dealAnalysisResultValue() {
    return this.dealAnalysisState.dealAnalysisResultValue;
  }

  formatIncomePieChartData(year) {
    let dealAnalysisSummary = this.dealAnalysisState.dealAnalysisResultValue;
    let incomeChartData: incomePieChartCategories = {
      operatingIncome: 0,
      monthlyRent: 0,
    };
    if (dealAnalysisSummary[year] && dealAnalysisSummary[year].revenue) {
      incomeChartData.operatingIncome =
        incomeChartData.operatingIncome + dealAnalysisSummary[year].revenue.operatingIncome;
      incomeChartData.monthlyRent = incomeChartData.monthlyRent + dealAnalysisSummary[year].revenue.monthlyRent;
    }
    return incomeChartData;
  }

  formatExpensePieChartData(year) {
    let dealAnalysisSummary = this.dealAnalysisState.dealAnalysisResultValue;
    let expenseChartData: expensePieChartCategories = {
      propertyTax: 0,
      insurance: 0,
      hoaFee: 0,
      propertyManagement: 0,
      maintenance: 0,
      warrantyService: 0,
      accounting: 0,
    };
    if (dealAnalysisSummary[year] && dealAnalysisSummary[year].expense) {
      expenseChartData.propertyTax = expenseChartData.propertyTax + dealAnalysisSummary[year].expense.propertyTax;
      expenseChartData.insurance = expenseChartData.insurance + dealAnalysisSummary[year].expense.insurance;
      expenseChartData.hoaFee = expenseChartData.hoaFee + dealAnalysisSummary[year].expense.hoaFee;
      expenseChartData.propertyManagement =
        expenseChartData.propertyManagement + dealAnalysisSummary[year].expense.propertyManagement;
      expenseChartData.maintenance = expenseChartData.maintenance + dealAnalysisSummary[year].expense.maintenance;
      expenseChartData.warrantyService =
        expenseChartData.warrantyService + dealAnalysisSummary[year].expense.warrantyService;
      expenseChartData.accounting = expenseChartData.accounting + dealAnalysisSummary[year].expense.accounting;
    }
    return expenseChartData;
  }

  formatLineChartData() {
    let dealAnalysisSummary = this.dealAnalysisState.dealAnalysisResultValue;
    let lineChartData: lineChartCategories = {
      operatingIncome: [],
      operatingExpense: [],
      cashFlow: [],
      taxableIncome: [],
      equity: [],
      profitOnSale: [],
      capRateOnPurchasePriceRatio: [],
      capRateOnFMVRatio: [],
      cashOnCashReturnRatio: [],
      returnOnInvestmentRatio: [],
      netProceed: [],
      totalCashInvested: [],
      capRateOnCurrentValueRatio: [],
    };

    let listOfYears = Object.keys(dealAnalysisSummary);
    listOfYears.forEach((year) => {
      if (dealAnalysisSummary[year].revenue) {
        dealAnalysisSummary[year].revenue.operatingIncome
          ? lineChartData.operatingIncome.push(
            Math.round(dealAnalysisSummary[year].revenue.operatingIncome * 10000) / 10000
          )
          : lineChartData.operatingIncome.push(null);
      }

      if (dealAnalysisSummary[year].expense) {
        dealAnalysisSummary[year].expense.operatingExpense
          ? lineChartData.operatingExpense.push(
            Math.round(dealAnalysisSummary[year].expense.operatingExpense * 10000) / 10000
          )
          : lineChartData.operatingExpense.push(null);
      }

      if (dealAnalysisSummary[year].cashFlow) {
        dealAnalysisSummary[year].cashFlow.cashFlow
          ? lineChartData.cashFlow.push(Math.round(dealAnalysisSummary[year].cashFlow.cashFlow * 10000) / 10000)
          : lineChartData.cashFlow.push(null);
      }

      if (dealAnalysisSummary[year].taxDeduction) {
        dealAnalysisSummary[year].taxDeduction.taxableIncome
          ? lineChartData.taxableIncome.push(
            Math.round(dealAnalysisSummary[year].taxDeduction.taxableIncome * 10000) / 10000
          )
          : lineChartData.taxableIncome.push(null);
      }

      if (dealAnalysisSummary[year].equityAppreciation) {
        dealAnalysisSummary[year].equityAppreciation.equity
          ? lineChartData.equity.push(Math.round(dealAnalysisSummary[year].equityAppreciation.equity * 10000) / 10000)
          : lineChartData.equity.push(null);
      }

      if (dealAnalysisSummary[year].salePrediction) {
        dealAnalysisSummary[year].salePrediction.profitOnSale
          ? lineChartData.profitOnSale.push(
            Math.round(dealAnalysisSummary[year].salePrediction.profitOnSale * 10000) / 10000
          )
          : lineChartData.profitOnSale.push(null);

        dealAnalysisSummary[year].salePrediction.netProceed
          ? lineChartData.netProceed.push(
            Math.round(dealAnalysisSummary[year].salePrediction.netProceed * 10000) / 10000
          )
          : lineChartData.netProceed.push(null);

        dealAnalysisSummary[year].salePrediction.totalCashInvested
          ? lineChartData.totalCashInvested.push(
            Math.round(dealAnalysisSummary[year].salePrediction.totalCashInvested * 10000) / 10000
          )
          : lineChartData.totalCashInvested.push(null);
      }
      if (dealAnalysisSummary[year].returnIndicator) {
        let capRateOnPurchasePrice = dealAnalysisSummary[year].returnIndicator.capRateOnPurchasePriceRatio
          ? Math.round(dealAnalysisSummary[year].returnIndicator.capRateOnPurchasePriceRatio * 100 * 10000) / 10000
          : null;
        lineChartData.capRateOnPurchasePriceRatio.push(capRateOnPurchasePrice);

        let capRateOnCurrentValue = dealAnalysisSummary[year].returnIndicator.capRateOnCurrentValueRatio
          ? Math.round(dealAnalysisSummary[year].returnIndicator.capRateOnCurrentValueRatio * 100 * 10000) / 10000
          : null;
        lineChartData.capRateOnCurrentValueRatio.push(capRateOnCurrentValue);

        let capRateOnFMV = dealAnalysisSummary[year].returnIndicator.capRateOnFMVRatio
          ? Math.round(dealAnalysisSummary[year].returnIndicator.capRateOnFMVRatio * 100 * 10000) / 10000
          : null;
        lineChartData.capRateOnFMVRatio.push(capRateOnFMV);

        let cashOnCashReturn = dealAnalysisSummary[year].returnIndicator.cashOnCashReturnRatio
          ? Math.round(dealAnalysisSummary[year].returnIndicator.cashOnCashReturnRatio * 100 * 10000) / 10000
          : null;
        lineChartData.cashOnCashReturnRatio.push(cashOnCashReturn);

        let returnOnEquity = dealAnalysisSummary[year].returnIndicator.returnOnInvestmentRatio
          ? Math.round(dealAnalysisSummary[year].returnIndicator.returnOnInvestmentRatio * 100 * 10000) / 10000
          : null;
        lineChartData.returnOnInvestmentRatio.push(returnOnEquity);
      }
    });

    return lineChartData;
  }

  findMonthlyMortgagePayment(monthlyMortgagePaymentInput) {
    return this.dealAnalysisService.computeMonthlyMortgagePayment(monthlyMortgagePaymentInput);
  }

  findInsuranceAmount(insuranceAmountInput) {
    return this.dealAnalysisService.computeInsuranceAmount(insuranceAmountInput);
  }

  convertData(data) {
    let transformedData = {};
    for (const year in data) {
      const yearData = data[year];
      for (const category in yearData) {
        for (const key in yearData[category]) {
          if (!transformedData[key]) {
            transformedData[key] = [];
          }
          transformedData[key].push(yearData[category][key]);
        }
      }
    }
    this.displayAnalysisResults$.next(transformedData);

  }

  filterDisplayResult(result, years) {
    const displayResult = {};
    for (const year in result) {
      if (Object.prototype.hasOwnProperty.call(result, year) && years.includes(Number(year))) {
        const yearlyValue = result[year];
        for (const groupName in yearlyValue) {
          if (Object.prototype.hasOwnProperty.call(yearlyValue, groupName)) {
            const resultGroup = yearlyValue[groupName];
            for (const parameter in resultGroup) {
              if (!displayResult[parameter]) {
                displayResult[parameter] = [];
              }
              const value = resultGroup[parameter];
              displayResult[parameter].push(value);
            }
          }
        }
      }
    }
    this.displayAnalysisResult$.next(displayResult);
  }

  displayEquityInfo(result, years) {
    const displayResult = {};
    for (const year in result) {
      if (Object.prototype.hasOwnProperty.call(result, year) && years.includes(Number(year))) {
        const yearlyValue = result[year];
        for (const groupName in yearlyValue) {
          if (Object.prototype.hasOwnProperty.call(yearlyValue, groupName)) {
            const resultGroup = yearlyValue[groupName];
            for (const parameter in resultGroup) {
              if (!displayResult[parameter]) {
                displayResult[parameter] = [];
              }
              const value = resultGroup[parameter];
              displayResult[parameter].push(value);
            }
          }
        }
      }
    }
    return displayResult;
  }

  updateDisplayYear(yearList) {
    this.displayYearList$.next(yearList);
  }

  getFieldLabel(fieldName) {
    let fieldLabel;
    switch (fieldName) {
      case 'grossRent':
        fieldLabel = 'Gross Rent';
        break;
      case 'monthlyRent':
        fieldLabel = 'Monthly Rent';
        break;
      case 'vacancy':
        fieldLabel = 'Vacancy';
        break;
      case 'operatingIncome':
        fieldLabel = 'Operating Income';
        break;
      case 'propertyTax':
        fieldLabel = 'Property Taxes';
        break;
      case 'insurance':
        fieldLabel = 'Insurance';
        break;
      case 'hoaFee':
        fieldLabel = 'HOA Fee';
        break;
      case 'propertyManagement':
        fieldLabel = 'Property Management';
        break;
      case 'maintenance':
        fieldLabel = 'Repairs & Maintenance';
        break;
      case 'warrantyService':
        fieldLabel = 'Warranty Service';
        break;
      case 'accounting':
        fieldLabel = 'Accounting';
        break;
      case 'operatingExpense':
        fieldLabel = 'Operating Expenses';
        break;
      case 'netOperatingIncome':
        fieldLabel = 'Net Operating Income';
        break;
      case 'mortgagePayment':
        fieldLabel = 'Mortgage Payment';
        break;
      case 'cashFlow':
        fieldLabel = 'Cash Flow';
        break;
      case 'mortgageInterest':
        fieldLabel = 'Mortgage Interest';
        break;
      case 'depreciation':
        fieldLabel = 'Depreciation';
        break;
      case 'totalDeduction':
        fieldLabel = 'Total Deductions';
        break;
      case 'taxableIncome':
        fieldLabel = 'Taxable Income';
        break;
      case 'propertyValue':
        fieldLabel = 'Property Value';
        break;
      case 'mortgageBalance':
        fieldLabel = 'Mortgage Balance';
        break;
      case 'equity':
        fieldLabel = 'Your Equity';
        break;
      case 'sellingCost':
        fieldLabel = 'Selling Costs';
        break;
      case 'netProceed':
        fieldLabel = 'Net Proceeds';
        break;
      case 'cumulativeCashFlow':
        fieldLabel = 'Cumulative Cash Flow';
        break;
      case 'totalCashInvested':
        fieldLabel = 'Total Cash Invested';
        break;
      case 'profitOnSale':
        fieldLabel = 'Profit On Sale';
        break;
      case 'capRateOnPurchasePriceRatio':
        fieldLabel = 'Cap Rate on Purchase Price';
        break;
      case 'capRateOnFMVRatio':
        fieldLabel = 'Cap Rate on FMV';
        break;
      case 'cashOnCashReturnRatio':
        fieldLabel = 'Cash on Cash Return';
        break;
      case 'returnOnInvestmentRatio':
        fieldLabel = 'Return on Investment';
        break;
      case 'returnOnEquityRatio':
        fieldLabel = 'Return on Equity';
        break;
    }
    return fieldLabel;
  }

  setDefaultDealAnalysisValues(property) {
    let defaultInput: object = {};
    if (this.MyHomeState.DealAnalysisHomeValue && this.MyHomeState.DealAnalysisHomeValue['customPurchaseValue']) {
      defaultInput['uPurchasePrice'] = this.MyHomeState.DealAnalysisHomeValue['customPurchaseValue'];
    } else {
      if (
        property &&
        property.Lst_StandardStatus &&
        ['Active', 'Active Under Contract', 'Pending'].includes(property.Lst_StandardStatus)
      ) {
        defaultInput['uPurchasePrice'] = parseInt(property.Lst_ListPrice, 10);
      } else if (property.Lst_CloseDate && this.isMoreThanSixMonthsAgo(property.Lst_CloseDate)) {
        defaultInput['uPurchasePrice'] = parseInt(this.getCurrentAVMValue(property), 10);
      } else if (property.LastSaleDate && this.isMoreThanSixMonthsAgo(property.LastSaleDate)) {
        defaultInput['uPurchasePrice'] = parseInt(this.getCurrentAVMValue(property), 10);
      } else {
        defaultInput['uPurchasePrice'] = property.ClosePrice
          ? parseInt(property.ClosePrice, 10)
          : parseInt(this.getCurrentAVMValue(property), 10);
      }
    }

    defaultInput['uLandValue'] = property && property.AssessedLandValue ? parseInt(property.AssessedLandValue, 10) : 0;
    defaultInput['uPropertyTaxAmount'] =
      property && property.TaxAnnualAmount
        ? typeof property.TaxAnnualAmount == 'string' && property.TaxAnnualAmount.indexOf('$') > -1
          ? parseFloat(property.TaxAnnualAmount.replace(/[$,]/g, ''))
          : parseFloat(property.TaxAnnualAmount)
        : 0;

    if (this.appState.appCompanyAliasValue == 'opscan') defaultInput['uMonthlyRent'] = 0;
    else
      defaultInput['uMonthlyRent'] = this.MyHomeState.DealAnalysisHomeValue['customPotentialMonthly']
        ? this.MyHomeState.DealAnalysisHomeValue['customPotentialMonthly']
        : property && property.RentValue
          ? property.RentValue
          : 0;

    defaultInput['uAssessedPropertyValue'] =
      property && property.AssessedValue ? parseInt(property.AssessedValue, 10) : 0;
    defaultInput['uFairMarketValue'] =
      property && property.CurrentAVMValue
        ? typeof property.CurrentAVMValue == 'string' && property.CurrentAVMValue.indexOf('$') > -1
          ? parseInt(property.CurrentAVMValue.replace(/[$,]/g, ''), 10)
          : parseInt(property.CurrentAVMValue, 10)
        : 0;
    defaultInput['uState'] = property && property.StateOrProvince ? property.StateOrProvince : null;
    defaultInput['listingId'] =
      property && property.ListingId
        ? property.ListingId
        : property && property.Lst_SI_ListingId
          ? property.Lst_SI_ListingId
          : null;
    this.dealAnalysisState.defaultDealAnalysisInputValue = defaultInput;
  }

  isMoreThanSixMonthsAgo(dateString) {
    const date = new Date(dateString);
    const currentDate = new Date();

    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    const sixMonthsAgoPlusOne = new Date(sixMonthsAgo);
    sixMonthsAgoPlusOne.setMilliseconds(sixMonthsAgoPlusOne.getMilliseconds() + 1);

    return date < sixMonthsAgoPlusOne;
  }

  getCurrentAVMValue(property) {
    if (property && property.CurrentAVMValue) {
      if (typeof property.CurrentAVMValue === 'string' && property.CurrentAVMValue.indexOf('$') > -1) {
        return property.CurrentAVMValue.replace(/[$,]/g, '');
      } else {
        return property.CurrentAVMValue;
      }
    }
    return '0'; // Return '0' as default if CurrentAVMValue is not valid
  }
}
