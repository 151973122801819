import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { SharedModule } from 'src/app/_shared/shared.module';
import { ConfirmDialogWithControlsComponent } from './confirm-dialog-with-controls/confirm-dialog-with-controls.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DialogComponent } from './dialog/dialog.component';
import { UnsavedChangesDialogComponent } from './unsaved-changes-dialog/unsaved-changes-dialog/unsaved-changes-dialog.component';

@NgModule({
  declarations: [DialogComponent, ConfirmDialogComponent, ConfirmDialogWithControlsComponent, UnsavedChangesDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    FormsModule,
    MatCheckboxModule,
    MatInputModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatIconModule,
    SharedModule,
  ],
  exports: [DialogComponent, ConfirmDialogComponent, ConfirmDialogWithControlsComponent],
})
export class AlertsModule {}
