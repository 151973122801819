<div class="dash_base">
  <div class="loadingMapFull" *ngIf="isLoadingDealList">
    <div class="flex_center fullHeight">
      <mat-spinner [diameter]="30" class="mr-3"></mat-spinner> Please wait while we load
    </div>
  </div>
</div>

<mat-sidenav-container [ngStyle]="{ 'z-index': isPropertySheetOpened ? 1 : 10 }">
  <mat-sidenav
    class="main row deal_analysis"
    #sidenav
    mode="over"
    position="end"
    [opened]="dealAnalysisSheetFacade.isSheetOpen() | async"
    disableClose
  >
    <div class="sheet-header">
      <h4>{{ dealSheetHeaderName }}</h4>
      <button type="button" class="prop_dtls_closeBtn float-right" (click)="closeDealAnalysisSheet()">
        <img src="../../../../assets/images/close.svg" />
      </button>
      <div
        class="btn-group float-right mr-md-5"
        *ngIf="isDealOwned && isEmptyObject(myHomeState.DealAnalysisHomeValue) && !navigatedFromMyPropertyPage"
      >
        <button
          *ngIf="companyName != 'homehunt' || (companyName == 'homehunt' && userType != 'agent')"
          type="button"
          color="primary"
          mat-flat-button
          class="pt-0 save-btn"
          (click)="saveDeal('save')"
        >
          <span class="d-sm-inline-block pl-1">Save</span>
        </button>
        <div
          class="btn-group"
          ngbDropdown
          role="group"
          aria-label="Button group with nested dropdown"
          style="height: 29px"
          *ngIf="companyName != 'homehunt' || (companyName == 'homehunt' && userType != 'agent')"
        >
          <button class="btn btn-primary pt-0 dropdown-toggle-split" ngbDropdownToggle></button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button mat-flat-button color="primary" (click)="openDialog('save')" class="w-100">
              <mat-icon>save</mat-icon>
              Save As
            </button>
          </div>
        </div>
      </div>
      <div class="btn-group float-right mr-md-5" *ngIf="navigatedFromMyPropertyPage">
        <button
          *ngIf="companyName != 'homehunt' || (companyName == 'homehunt' && userType != 'agent')"
          type="button"
          color="primary"
          mat-flat-button
          class="pt-0 save-btn"
          (click)="saveRentalAnalysisDeal('saveRentalAnalysis')"
        >
          <span class="d-sm-inline-block pl-1">Save</span>
        </button>
        <div
          class="btn-group"
          ngbDropdown
          role="group"
          aria-label="Button group with nested dropdown"
          style="height: 29px"
        >
          <button class="btn btn-primary pt-0 dropdown-toggle-split" ngbDropdownToggle></button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button mat-flat-button color="primary" (click)="openRentalDialog('saveRentalAnalysis')" class="w-100">
              <mat-icon>save</mat-icon>
              Save As
            </button>
          </div>
        </div>
      </div>
      <button type="button" class="float-right mr-3 icon-btn" mat-stroked-button (click)="reset()" matTooltip="Reset">
        <mat-icon inline>refresh</mat-icon>
      </button>

      <button
        type="button"
        mat-stroked-button
        class="float-right mr-3 icon-btn"
        *ngIf="isDealOwned"
        (click)="shareDeal()"
        matTooltip="Share"
      >
        <mat-icon inline>share</mat-icon>
      </button>
    </div>
    <div class="sub-header">
      <div class="row">
        <div class="col-md-1 col-sm-1 col-12 d-grid">
          <img
            class="mb-0 img-fluid"
            [src]="
              listingImageBaseUrl + '?ListingId=' + (activePropertyDetails?.Lst_ListingId || 'null') + '&imageName=1'
            "
            style="height: 52px; margin: auto"
          />
        </div>
        <div class="col-md-4 col-sm-3 col-12 d-grid pl-md-0">
          <div class="ads-div">
            <h5
              class="mb-0"
              (click)="openingPropertySheet()"
              *ngIf="activePropertyDetails?.propertyAddress"
              [propertyAddress]="activePropertyDetails.Address"
            >
              {{ activePropertyDetails.propertyAddress || activePropertyDetails.StreetAddress | IsValueExist }}<br />
              <span
                (click)="openingPropertySheet()"
                class="mb-0 d-inline-block sub-ads"
                *ngIf="activePropertyDetails?.City"
                [propertyAddress]="activePropertyDetails.Address"
                >{{ activePropertyDetails.City ? activePropertyDetails?.City : '' }}
                {{ activePropertyDetails.StateOrProvince ? ', ' + activePropertyDetails?.StateOrProvince : '' }}
                {{ activePropertyDetails.PostalCode ? ' ' + activePropertyDetails?.PostalCode : '' }}</span
              >
            </h5>
            <mat-chip class="applied-filter ml-2">
              {{ activePropertyDetails?.PropertySubType }}
            </mat-chip>
          </div>
        </div>
        <div class="col-md-7 col-sm-8 col-12 m-value">
          <span class="prop-hlt">
            <ul class="prop_highlight pl-2 mb-0 mr-2">
              <li [matTooltip]="'Bed'">
                <img src="../../assets/images/bed.svg" width="24" />
                <p class="ipad_amn_break ml-1">
                  {{ activePropertyDetails?.BedroomsTotal | number: '1.0-0' | IsValueExist }}
                </p>
              </li>
              <li [matTooltip]="'Bath'">
                <img src="../../assets/images/bath.svg" width="24" />
                <p class="ipad_amn_break ml-1">
                  {{ activePropertyDetails?.BathroomsTotalInteger | number: '1.0-0' | IsValueExist }}
                </p>
              </li>
              <li [matTooltip]="'Living Area'">
                <img src="../../assets/images/living_area.svg" width="24" />
                <p class="ipad_amn_break ml-1">
                  {{ activePropertyDetails?.LivingArea | number: '1.0-0' | IsValueExist }}<small> Sq.ft</small>
                </p>
              </li>
              <li [matTooltip]="'Lot Size Square Feet'">
                <img src="../../assets/images/land_area.svg" width="24" />
                <p
                  class="ipad_amn_break ml-1"
                  [innerHTML]="activePropertyDetails?.LotSizeSquareFeet | SqftToAcresConverter"
                ></p>
              </li>
            </ul>
            <div class="pr-2 pl-0 pl-sm-2">
              <span>Current Value</span><br />
              <h4 class="mb-0 mc-value">
                <b
                  *ngIf="activeAVMHistory && activeAVMHistory.length > 0; else noActiveAVMHistory"
                  [innerHTML]="activeAVMHistory[0].AVMValue | CurrencyFormatter | IsValueExist"
                ></b>
                <ng-template #noActiveAVMHistory>
                  <b>--</b>
                </ng-template>
              </h4>
            </div>
            <div class="pr-2 pl-2">
              <span>Rent Value</span><br />
              <h4 class="mr-value">
                <span
                  ><b>{{ activePropertyDetails?.RentValue | CurrencyFormatter | IsValueExist }}</b></span
                >
              </h4>
            </div>
          </span>
        </div>
      </div>
    </div>
    <div class="sheet-content">
      <div class="row">
        <div class="col-md-6 col-sm-12 col-12 mb-4">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="row">
                  <div class="col-12">
                    <h5 class="mb-0">Income Evaluation</h5>
                  </div>
                  <div class="col-6" *ngIf="!showIncomeDetails">
                    <button mat-icon-button (click)="toggleIncomeDetails()">
                      <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="panel-ht">
              <div class="row">
                <div class="col-12">
                  <div class="chart-img">
                    <button (click)="toggleChart('incomeEvaluation')">
                      <img src="../../../../assets/images/chart-button.png" alt="view graph" />
                    </button>
                  </div>
                  <div class="table-responsive">
                    <table class="table mtable income-table">
                      <thead>
                        <tr>
                          <th class="border1">&nbsp;</th>
                          <th class="border1">Monthly</th>
                          <th>Annual</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="border1">
                            Rental Income
                            <mat-icon
                              [matTooltip]="dealAnalysisConfig.evaluationTableToolTip.montlyRent"
                              [matTooltipPosition]="'right'"
                              matTooltipClass="line-break"
                              class="help-icon"
                              >help_outline</mat-icon
                            >
                          </td>
                          <td class="border1 fw-600">
                            <span>
                              {{
                                computedDealAnalysisResult?.monthlyRent &&
                                computedDealAnalysisResult.monthlyRent.length > 0
                                  ? !isNumber(computedDealAnalysisResult.monthlyRent[0])
                                    ? computedDealAnalysisResult.monthlyRent[0] || '--'
                                    : (computedDealAnalysisResult.monthlyRent[0] | currency: 'USD':'symbol':'1.0-0')
                                  : '--'
                              }}
                            </span>
                          </td>
                          <td class="fw-600">
                            <span>
                              {{
                                computedDealAnalysisResult?.monthlyRent &&
                                computedDealAnalysisResult.monthlyRent.length > 0
                                  ? !isNumber(computedDealAnalysisResult.monthlyRent[0])
                                    ? computedDealAnalysisResult.monthlyRent[0] || '--'
                                    : (computedDealAnalysisResult.monthlyRent[0] * 12
                                      | currency: 'USD':'symbol':'1.0-0')
                                  : '--'
                              }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="border1">
                            Expenses<mat-icon
                              [matTooltip]="dealAnalysisConfig.evaluationTableToolTip.expenses"
                              [matTooltipPosition]="'right'"
                              matTooltipClass="line-break"
                              class="help-icon"
                              >help_outline</mat-icon
                            >
                          </td>
                          <td class="border1 fw-600">
                            <span>
                              {{
                                computedDealAnalysisResult?.operatingExpense &&
                                computedDealAnalysisResult.operatingExpense.length > 0
                                  ? !isNumber(computedDealAnalysisResult.operatingExpense[0])
                                    ? computedDealAnalysisResult.operatingExpense[0] || '--'
                                    : (computedDealAnalysisResult.operatingExpense[0] / 12
                                      | currency: 'USD':'symbol':'1.0-0')
                                  : '--'
                              }}
                            </span>
                          </td>
                          <td class="fw-600">
                            <span
                              >{{
                                computedDealAnalysisResult?.operatingExpense &&
                                computedDealAnalysisResult.operatingExpense.length > 0
                                  ? !isNumber(computedDealAnalysisResult.operatingExpense[0])
                                    ? computedDealAnalysisResult.operatingExpense[0] || '--'
                                    : (computedDealAnalysisResult.operatingExpense[0]
                                      | currency: 'USD':'symbol':'1.0-0')
                                  : '--'
                              }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="border1">
                            Net Operating Income<mat-icon
                              [matTooltip]="dealAnalysisConfig.evaluationTableToolTip.netOperatingIncome"
                              [matTooltipPosition]="'right'"
                              matTooltipClass="line-break"
                              class="help-icon"
                              >help_outline</mat-icon
                            >
                          </td>
                          <td class="border1 fw-600">
                            <span>
                              {{
                                computedDealAnalysisResult?.netOperatingIncome &&
                                computedDealAnalysisResult.netOperatingIncome.length > 0
                                  ? !isNumber(computedDealAnalysisResult.netOperatingIncome[0])
                                    ? computedDealAnalysisResult.netOperatingIncome[0] || '--'
                                    : (computedDealAnalysisResult.netOperatingIncome[0] / 12
                                      | currency: 'USD':'symbol':'1.0-0')
                                  : '--'
                              }}
                            </span>
                          </td>
                          <td class="fw-600">
                            <span
                              >{{
                                computedDealAnalysisResult?.netOperatingIncome &&
                                computedDealAnalysisResult.netOperatingIncome.length > 0
                                  ? !isNumber(computedDealAnalysisResult.netOperatingIncome[0])
                                    ? computedDealAnalysisResult.netOperatingIncome[0] || '--'
                                    : (computedDealAnalysisResult.netOperatingIncome[0]
                                      | currency: 'USD':'symbol':'1.0-0')
                                  : '--'
                              }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="border1">
                            Mortgage Payment<mat-icon
                              [matTooltip]="dealAnalysisConfig.evaluationTableToolTip.mortgagePayment"
                              [matTooltipPosition]="'right'"
                              matTooltipClass="line-break"
                              class="help-icon"
                              >help_outline</mat-icon
                            >
                          </td>
                          <td class="border1 fw-600">
                            <span>
                              {{
                                computedDealAnalysisResult?.mortgagePayment &&
                                computedDealAnalysisResult.mortgagePayment.length > 0
                                  ? !isNumber(computedDealAnalysisResult.mortgagePayment[0])
                                    ? computedDealAnalysisResult.mortgagePayment[0] || '--'
                                    : (computedDealAnalysisResult.mortgagePayment[0] / 12
                                      | currency: 'USD':'symbol':'1.0-0')
                                  : '--'
                              }}
                            </span>
                          </td>
                          <td class="fw-600">
                            <span
                              >{{
                                computedDealAnalysisResult?.mortgagePayment &&
                                computedDealAnalysisResult.mortgagePayment.length > 0
                                  ? !isNumber(computedDealAnalysisResult.mortgagePayment[0])
                                    ? computedDealAnalysisResult.mortgagePayment[0] || '--'
                                    : (computedDealAnalysisResult.mortgagePayment[0] | currency: 'USD':'symbol':'1.0-0')
                                  : '--'
                              }}
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td class="border1">
                            Cash Flow<mat-icon
                              [matTooltip]="dealAnalysisConfig.evaluationTableToolTip.cashFlow"
                              [matTooltipPosition]="'right'"
                              matTooltipClass="line-break"
                              class="help-icon"
                              >help_outline</mat-icon
                            >
                          </td>
                          <td class="border1 fw-600">
                            <span
                              >{{
                                computedDealAnalysisResult?.cashFlow && computedDealAnalysisResult.cashFlow.length > 0
                                  ? !isNumber(computedDealAnalysisResult.cashFlow[0])
                                    ? computedDealAnalysisResult.cashFlow[0] || '--'
                                    : (computedDealAnalysisResult.cashFlow[0] / 12 | currency: 'USD':'symbol':'1.0-0')
                                  : '--'
                              }}
                            </span>
                          </td>
                          <td class="fw-600">
                            <span>
                              {{
                                computedDealAnalysisResult?.cashFlow && computedDealAnalysisResult.cashFlow.length > 0
                                  ? !isNumber(computedDealAnalysisResult.cashFlow[0])
                                    ? computedDealAnalysisResult.cashFlow[0] || '--'
                                    : (computedDealAnalysisResult.cashFlow[0] | currency: 'USD':'symbol':'1.0-0')
                                  : '--'
                              }}
                            </span>
                          </td>
                        </tr>
                        <!-- <cap rate></cap> -->
                        <tr>
                          <td class="border1">Cap Rate</td>
                          <td class="border1 fw-600">
                            <span> -- </span>
                          </td>
                          <td class="fw-600">
                            <span *ngIf="showIncomeDetails">
                              {{
                                propertyInfo?.CapRate || activePropertyDetails?.CapRate
                                  ? (propertyInfo?.CapRate || activePropertyDetails?.CapRate) + '%'
                                  : '--'
                              }}
                            </span>
                          </td>
                        </tr>

                        <!-- <cash on cash></cash> -->
                        <tr>
                          <td class="border1">
                            Cash On Cash<mat-icon
                              [matTooltip]="dealAnalysisConfig.evaluationTableToolTip.CashOnCash"
                              [matTooltipPosition]="'right'"
                              matTooltipClass="line-break"
                              class="help-icon"
                              >help_outline</mat-icon
                            >
                          </td>
                          <td class="border1 fw-600">
                            <span> -- </span>
                          </td>
                          <td class="fw-600">
                            <span
                              *ngIf="computedDealAnalysisResult && computedDealAnalysisResult.cashOnCashReturnRatio"
                            >
                              {{
                                computedDealAnalysisResult.cashOnCashReturnRatio.length > 0
                                  ? (computedDealAnalysisResult.cashOnCashReturnRatio[0] * 100).toFixed(2) + '%'
                                  : '--'
                              }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <!-- chart -->
              <div class="row mt-3" *ngIf="showIncomeEvalChart">
                <app-line-chart
                  [chartData]="returnIndicatorSummary"
                  [chartSettings]="returnIndicatorSettings"
                  *ngIf="returnIndicatorSummary && returnIndicatorSettings"
                >
                </app-line-chart>
              </div>
            </div>
          </mat-expansion-panel>
        </div>

        <div class="col-md-6 col-sm-12 col-12 mb-4">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="row">
                  <div class="col-12">
                    <h5 class="mb-0">Equity Evaluation</h5>
                  </div>
                  <div class="col-6" *ngIf="!showEquityDetails">
                    <button mat-icon-button (click)="toggleEquityDetails()">
                      <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="panel-ht">
              <div class="row" *ngIf="showEquityDetails">
                <div class="col-8">
                  <h5 class="mt-3">Years to Profit : {{ profitOnSaleYear }} years</h5>
                </div>
                <div class="col-4">
                  <div class="chart-img">
                    <button (click)="toggleChart('equityEvaluation')">
                      <img src="../../../../assets/images/chart-button.png" alt="view graph" />
                    </button>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table mtable income-table">
                  <thead>
                    <tr>
                      <th class="border1">&nbsp;</th>
                      <th class="border1">After {{ profitOnSaleYear }} Years</th>
                      <th>After {{ upcompingEquityEval }} years</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="border1">
                        Total Cash Invested<mat-icon
                          [matTooltip]="dealAnalysisConfig.evaluationTableToolTip.totalCashInvested"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                          class="help-icon"
                          >help_outline</mat-icon
                        >
                      </td>
                      <td class="border1 fw-600">
                        <span>
                          {{
                            !isNumber(totalCashInvested)
                              ? totalCashInvested || '--'
                              : (totalCashInvested | currency: 'USD':'symbol':'1.0-0')
                          }}
                        </span>
                      </td>
                      <td class="fw-600">
                        <span>
                          {{
                            !isNumber(upcomingTotalCashInvested)
                              ? upcomingTotalCashInvested || '--'
                              : (upcomingTotalCashInvested | currency: 'USD':'symbol':'1.0-0')
                          }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="border1">
                        Your Equity<mat-icon
                          [matTooltip]="dealAnalysisConfig.evaluationTableToolTip.yourEquity"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                          class="help-icon"
                          >help_outline</mat-icon
                        >
                      </td>
                      <td class="border1 fw-600">
                        <span>
                          {{ !isNumber(equity) ? equity || '--' : (equity | currency: 'USD':'symbol':'1.0-0') }}
                        </span>
                      </td>
                      <td class="fw-600">
                        <span>
                          {{
                            !isNumber(upcomingEquity)
                              ? upcomingEquity || '--'
                              : (upcomingEquity | currency: 'USD':'symbol':'1.0-0')
                          }}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td class="border1">
                        Property Value<mat-icon
                          [matTooltip]="dealAnalysisConfig.evaluationTableToolTip.propertyValue"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                          class="help-icon"
                          >help_outline</mat-icon
                        >
                      </td>
                      <td class="border1 fw-600">
                        <span>
                          {{
                            !isNumber(propertyValue)
                              ? propertyValue || '--'
                              : (propertyValue | currency: 'USD':'symbol':'1.0-0')
                          }}
                        </span>
                      </td>
                      <td class="fw-600">
                        <span
                          >{{
                            !isNumber(upcomingPropertyValue)
                              ? upcomingPropertyValue || '--'
                              : (upcomingPropertyValue | currency: 'USD':'symbol':'1.0-0')
                          }}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td class="border1">Mortgage Balance</td>
                      <td class="border1 fw-600">
                        <span>
                          {{
                            !isNumber(mortgageBalance)
                              ? mortgageBalance || '--'
                              : (mortgageBalance | currency: 'USD':'symbol':'1.0-0')
                          }}
                        </span>
                      </td>
                      <td class="fw-600">
                        <span
                          >{{
                            !isNumber(upcomingMortgageBalance)
                              ? upcomingMortgageBalance || '--'
                              : (upcomingMortgageBalance | currency: 'USD':'symbol':'1.0-0')
                          }}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td class="border1">
                        Net Proceeds<mat-icon
                          [matTooltip]="dealAnalysisConfig.evaluationTableToolTip.netProceeds"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                          class="help-icon"
                          >help_outline</mat-icon
                        >
                      </td>
                      <td class="border1 fw-600">
                        <span
                          >{{
                            !isNumber(netProceeds)
                              ? netProceeds || '--'
                              : (netProceeds | currency: 'USD':'symbol':'1.0-0')
                          }}
                        </span>
                      </td>
                      <td class="fw-600">
                        <span>
                          {{
                            !isNumber(upcomingNetProceeds)
                              ? upcomingNetProceeds || '--'
                              : (upcomingNetProceeds | currency: 'USD':'symbol':'1.0-0')
                          }}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td class="border1">
                        Profit On Sale<mat-icon
                          [matTooltip]="dealAnalysisConfig.evaluationTableToolTip.ProfitOnSale"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                          class="help-icon"
                          >help_outline</mat-icon
                        >
                      </td>
                      <td class="border1 fw-600">
                        <span>
                          {{
                            !isNumber(profitOnSale)
                              ? profitOnSale || '--'
                              : (profitOnSale | currency: 'USD':'symbol':'1.0-0')
                          }}
                        </span>
                      </td>
                      <td class="fw-600">
                        <span>
                          {{
                            !isNumber(upcomingProfitonSale)
                              ? upcomingProfitonSale || '--'
                              : (upcomingProfitonSale | currency: 'USD':'symbol':'1.0-0')
                          }}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td class="border1">
                        Return on Investment (ROI)<mat-icon
                          [matTooltip]="dealAnalysisConfig.evaluationTableToolTip.ROI"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                          class="help-icon"
                          >help_outline</mat-icon
                        >
                      </td>
                      <td class="border1 fw-600">
                        <span>
                          {{ returnOnInvestment ? (returnOnInvestment * 100).toFixed(2) + '%' : '--' }}
                        </span>
                      </td>
                      <td class="fw-600">
                        <span>
                          {{ upcomingReturnOnInvestment ? (upcomingReturnOnInvestment * 100).toFixed(2) + '%' : '--' }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="row mt-3" *ngIf="showEquityEvalChart">
                <app-line-chart
                  [chartData]="salePredictionSummary"
                  [chartSettings]="salePredictionSettings"
                  *ngIf="salePredictionSummary && salePredictionSettings"
                >
                </app-line-chart>
              </div>
            </div>
          </mat-expansion-panel>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 col-sm-12 col-12">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="row">
                  <div class="col-12">
                    <h5 class="mb-0">Income Analysis</h5>
                  </div>
                  <div class="col-6" *ngIf="!showIncomeDetails">
                    <button mat-icon-button (click)="toggleIncomeDetails()">
                      <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="dealAnalysisForm">
              <mat-tab-group class="analysis-tab" animationDuration="0ms">
                <mat-tab>
                  <ng-template mat-tab-label>Purchase & Renovation</ng-template>
                  <div class="row">
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Purchase Price
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.purchasePriceInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              currencyMask
                              autocomplete="off"
                              formControlName="uPurchasePrice"
                              placeholder="Value($)"
                              (blur)="onInputBlur('uPurchasePrice')"
                              (keydown.enter)="onInputEnter('uPurchasePrice')"
                            />
                            <span matPrefix>$</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uPurchasePrice.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Down Payment
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.downPaymentInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-5 col-sm-6 col-6 pr-2">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              formControlName="uDownPayment"
                              placeholder="Value(%)"
                              (blur)="onInputBlur('uDownPayment')"
                              (keydown.enter)="onInputEnter('uDownPayment')"
                              (keydown)="validateNumber($event)"
                            />
                            <span matSuffix>%</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uDownPayment.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                        <div class="col-md-7 col-sm-6 col-6 pl-2">
                          <mat-form-field class="w-100" floatLabel="never"
                            ><input
                              matInput
                              autocomplete="off"
                              placeholder="Value($)"
                              formControlName="uDownPaymentInDollors"
                              currencyMask
                              (blur)="onInputBlur('uDownPaymentInDollors')"
                              (keydown.enter)="onInputEnter('uDownPaymentInDollors')"
                            />
                            <span matPrefix>$</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uDownPaymentInDollors.errors" class="invalid-feedback ml-0">
                            Down Payment should be less than Purchase Price
                          </mat-error>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Land Value
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.landValueInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value($)"
                              formControlName="uLandValue"
                              currencyMask
                              (blur)="onInputBlur('uLandValue')"
                              (keydown.enter)="onInputEnter('uLandValue')"
                            />
                            <span matPrefix>$</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uLandValue.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Closing Costs
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.closingCostsInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value($)"
                              formControlName="uClosingCost"
                              currencyMask
                              (blur)="onInputBlur('uClosingCost')"
                              (keydown.enter)="onInputEnter('uClosingCost')"
                            />
                            <span matPrefix>$</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uClosingCost.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Renovation Costs
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.renovationCostsInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value($)"
                              formControlName="uRenovationCost"
                              currencyMask
                              (blur)="onInputBlur('uRenovationCost')"
                              (keydown.enter)="onInputEnter('uRenovationCost')"
                            />
                            <span matPrefix>$</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uRenovationCost.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>Mortgage Information</ng-template>
                  <div class="row">
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Loan Amount
                        <mat-icon
                          matSuffix
                          [matTooltip]="dealAnalysisConfig.tooltipText.loanAmountInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value($)"
                              formControlName="uLoanAmount"
                              currencyMask
                              (blur)="onInputBlur('uLoanAmount')"
                              (keydown.enter)="onInputEnter('uLoanAmount')"
                            />
                            <span matPrefix>$</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uLoanAmount.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                          <mat-error *ngIf="isloanAmountHigh" class="invalid-feedback ml-0"
                            >{{ 'Loan amount cannot be greater than purchase price' }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Interest Rate
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.interestRateInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value(%)"
                              (keydown)="validateNumber($event)"
                              formControlName="uInterestRate"
                            />
                            <span matSuffix>%</span>
                          </mat-form-field>

                          <mat-error
                            *ngIf="!navigatedFromMyPropertyPage && dealAnalysisForm.value.uInterestRate <= 0"
                            class="invalid-feedback ml-0"
                          >
                            Interest rate must be greater than 0%
                          </mat-error>

                          <mat-error *ngIf="dealAnalysisForm.value.uInterestRate > 20" class="invalid-feedback ml-0">
                            Enter a rate between 1 to 20
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Term
                        <mat-icon
                          matSuffix
                          [matTooltip]="dealAnalysisConfig.tooltipText.termInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              formControlName="uLoanTerm"
                              placeholder="Term"
                              currencyMask
                            />
                            <span matSuffix>yrs</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uLoanTerm.errors" class="invalid-feedback ml-0">
                            Please enter a valid term duration between 1 and 49 years
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Monthly Mortgage Payment
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.monthlyMortgagePaymentInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        >
                      </mat-label>
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              formControlName="uMonthlyMortgagePayment"
                              currencyMask
                              (blur)="onInputBlur('uMonthlyMortgagePayment')"
                              (keydown.enter)="onInputEnter('uMonthlyMortgagePayment')"
                            />
                            <span matPrefix>$</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uMonthlyMortgagePayment.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>Annual Expenses</ng-template>
                  <div class="row">
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Property Taxes
                        <mat-icon
                          matSuffix
                          [matTooltip]="dealAnalysisConfig.tooltipText.propertyTaxesInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value($)"
                              formControlName="uPropertyTaxAmount"
                              currencyMask
                              (blur)="onInputBlur('uPropertyTaxAmount')"
                              (keydown.enter)="onInputEnter('uPropertyTaxAmount')"
                            />
                            <span matPrefix>$</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uPropertyTaxAmount.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4" *ngIf="isAdvanceMode">
                      <mat-label>
                        Insurance
                        <mat-icon
                          matSuffix
                          [matTooltip]="dealAnalysisConfig.tooltipText.insuranceInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value($)"
                              formControlName="uInsuranceAmount"
                              currencyMask
                              (blur)="onInputBlur('uInsuranceAmount')"
                              (keydown.enter)="onInputEnter('uInsuranceAmount')"
                            />
                            <span matPrefix>$</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uInsuranceAmount.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4" *ngIf="isAdvanceMode">
                      <mat-label> HOA Fee </mat-label>
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value($)"
                              formControlName="uHoaFee"
                              currencyMask
                              (blur)="onInputBlur('uHoaFee')"
                              (keydown.enter)="onInputEnter('uHoaFee')"
                            />
                            <span matPrefix>$</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uHoaFee.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4" *ngIf="!isAdvanceMode">
                      <mat-label> Other Expenses </mat-label>
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              formControlName="uOtherExpenses"
                              placeholder="Value(%)"
                              (blur)="onInputBlur('uOtherExpenses')"
                              (keydown.enter)="onInputEnter('uOtherExpenses')"
                              (keydown)="validateNumber($event)"
                            />
                            <span matSuffix>%</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uOtherExpenses.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-6 col-12 mt-4" *ngIf="isAdvanceMode">
                      <mat-label> HOA Fee YoY % </mat-label>
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              placeholder="YOY%"
                              autocomplete="off"
                              placeholder="YoY %"
                              formControlName="uHoaFeeYoYPercent"
                              (blur)="onInputBlur('uHoaFeeYoYPercent')"
                              (keydown.enter)="onInputEnter('uHoaFeeYoYPercent')"
                              (keydown)="validateNumber($event)"
                            />
                            <span matSuffix>%</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uHoaFeeYoYPercent.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-6 col-12 mt-4" *ngIf="isAdvanceMode">
                      <mat-label>
                        Property Management
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.propertyManagementInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-5 col-sm-6 col-6 pr-2">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value(%)"
                              formControlName="uPropertyManagementPercent"
                              (blur)="onInputBlur('uPropertyManagementPercent')"
                              (keydown.enter)="onInputEnter('uPropertyManagementPercent')"
                              (keydown)="validateNumber($event)"
                            />
                            <span matSuffix>%</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uPropertyManagementPercent.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                        <div class="col-md-7 col-sm-6 col-6 pl-2">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value($)"
                              formControlName="uPropertyManagementInDollors"
                              currencyMask
                              (blur)="onInputBlur('uPropertyManagementInDollors')"
                              (keydown.enter)="onInputEnter('uPropertyManagementInDollors')"
                            />
                            <span matPrefix>$</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uPropertyManagementInDollors.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-6 col-12 mt-4" *ngIf="isAdvanceMode">
                      <mat-label>
                        Repairs & Maintenance
                        <mat-icon
                          matSuffix
                          [matTooltip]="dealAnalysisConfig.tooltipText.repairsAndMaintenanceInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-5 col-sm-6 col-6 pr-2">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value(%)"
                              formControlName="uMaintenancePercent"
                              (blur)="onInputBlur('uMaintenancePercent')"
                              (keydown.enter)="onInputEnter('uMaintenancePercent')"
                              (keydown)="validateNumber($event)"
                            />
                            <span matSuffix>%</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uMaintenancePercent.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                        <div class="col-md-7 col-sm-6 col-6 pl-2">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value($)"
                              formControlName="uMaintenanceInDollors"
                              currencyMask
                              (blur)="onInputBlur('uMaintenanceInDollors')"
                              (keydown.enter)="onInputEnter('uMaintenanceInDollors')"
                            />
                            <span matPrefix>$</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uMaintenanceInDollors.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-6 col-12 mt-4" *ngIf="isAdvanceMode">
                      <mat-label>
                        Improvements
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.improvementsInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value(%)"
                              formControlName="uImprovementPercent"
                              (blur)="onInputBlur('uImprovementPercent')"
                              (keydown.enter)="onInputEnter('uImprovementPercent')"
                              (keydown)="validateNumber($event)"
                            />
                            <span matSuffix>%</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uImprovementPercent.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4" *ngIf="isAdvanceMode">
                      <mat-label>
                        Warranty Service
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.warrantyServiceInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value($)"
                              formControlName="uWarrantyServiceCost"
                              currencyMask
                              prefix="$"
                              (blur)="onInputBlur('uWarrantyServiceCost')"
                              (keydown.enter)="onInputEnter('uWarrantyServiceCost')"
                            />
                            <span matPrefix>$</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uWarrantyServiceCost.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4" *ngIf="isAdvanceMode">
                      <mat-label>Warranty Service YoY%</mat-label>
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="YOY%"
                              formControlName="uWarrantyServiceYoYPercent"
                              (blur)="onInputBlur('uWarrantyServiceYoYPercent')"
                              (keydown.enter)="onInputEnter('uWarrantyServiceYoYPercent')"
                              (keydown)="validateNumber($event)"
                            />
                            <span matSuffix>%</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uWarrantyServiceYoYPercent.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4" *ngIf="isAdvanceMode">
                      <mat-label>
                        Accounting
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.accountingInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value($)"
                              formControlName="uAccountingCost"
                              currencyMask
                              prefix="$"
                              (blur)="onInputBlur('uAccountingCost')"
                              (keydown.enter)="onInputEnter('uAccountingCost')"
                            />
                            <span matPrefix>$</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uAccountingCost.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4" *ngIf="isAdvanceMode">
                      <mat-label> Accounting YoY %</mat-label>
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="YOY%"
                              formControlName="uAccountingYoYPercent"
                              (blur)="onInputBlur('uAccountingYoYPercent')"
                              (keydown.enter)="onInputEnter('uAccountingYoYPercent')"
                              (keydown)="validateNumber($event)"
                            />
                            <span matSuffix>%</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uAccountingYoYPercent.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>

                    <button mat-button color="primary" type="button" class="advance-mode" (click)="toggleAdvanceMode()">
                      {{ isAdvanceMode ? 'Simple Mode' : 'Advanced Mode' }}
                    </button>
                  </div>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>Revenue</ng-template>
                  <div class="row">
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Monthly Rent
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.monthlyRentInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value($)"
                              formControlName="uMonthlyRent"
                              currencyMask
                              (blur)="onInputBlur('uMonthlyRent')"
                              (keydown.enter)="onInputEnter('uMonthlyRent')"
                            />
                            <span matPrefix>$</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uMonthlyRent.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label> Monthly Rent YoY %</mat-label>
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="YOY%"
                              formControlName="uMonthlyRentYoYPercent"
                              (blur)="onInputBlur('uMonthlyRentYoYPercent')"
                              (keydown.enter)="onInputEnter('uMonthlyRentYoYPercent')"
                              (keydown)="validateNumber($event)"
                            />
                            <span matSuffix>%</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uMonthlyRentYoYPercent.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Vacancy Rate
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.rentChurnInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <mat-select formControlName="uRenterChurn">
                              <mat-option *ngFor="let option of vaccancyRateOptions" [value]="option.value">
                                {{ option.displayValue }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6 col-12 mt-4">
                      <mat-label>
                        New Tenant Search Time
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.newTenantSearchTimeInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Time"
                              formControlName="uTenantSearchDuration"
                              currencyMask
                              (blur)="onInputBlur('uTenantSearchDuration')"
                              (keydown.enter)="onInputEnter('uTenantSearchDuration')"
                            />
                            <span matSuffix>Months</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uTenantSearchDuration.errors" class="invalid-feedback ml-0">
                            Please enter months between 1-12
                          </mat-error>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>Value</ng-template>
                  <div class="row">
                    <div class="col-md-3 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Assessed Property Value
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.assessedPropertyValueInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12 pr-2">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value($)"
                              formControlName="uAssessedPropertyValue"
                              currencyMask
                              (blur)="onInputBlur('uAssessedPropertyValue')"
                              (keydown.enter)="onInputEnter('uAssessedPropertyValue')"
                            />
                            <span matPrefix>$</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uAssessedPropertyValue.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>Assessed value YOY% </mat-label>
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="YOY%"
                              formControlName="uAssessedPropertyValueYoYPercent"
                              (blur)="onInputBlur('uAssessedPropertyValueYoYPercent')"
                              (keydown.enter)="onInputEnter('uAssessedPropertyValueYoYPercent')"
                              (keydown)="validateNumber($event)"
                            />
                            <span matSuffix>%</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uAssessedPropertyValueYoYPercent.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label> Value appreciation YOY% </mat-label>
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="YOY%"
                              formControlName="uFairMarketValueYoYPercent"
                              (blur)="onInputBlur('uFairMarketValueYoYPercent')"
                              (keydown.enter)="onInputEnter('uFairMarketValueYoYPercent')"
                              (keydown)="validateNumber($event)"
                            />
                            <span matSuffix>%</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uFairMarketValueYoYPercent.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>Sale</ng-template>
                  <div class="row">
                    <div class="col-md-3 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Seller Agent Commission
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.sellerAgentCommissionInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-6 col-sm-6 col-6 pr-2">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value(%)"
                              formControlName="uSalesSellerAgentCommissionPercent"
                              (blur)="onInputBlur('uSalesSellerAgentCommissionPercent')"
                              (keydown.enter)="onInputEnter('uSalesSellerAgentCommissionPercent')"
                              (keydown)="validateNumber($event)"
                            />
                            <span matSuffix>%</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uSalesSellerAgentCommissionPercent.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6 pl-2">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value($)"
                              formControlName="uSalesSellerAgentComissionInDollors"
                              currencyMask
                              prefix="$"
                              (blur)="onInputBlur('uSalesSellerAgentComissionInDollors')"
                              (keydown.enter)="onInputEnter('uSalesSellerAgentComissionInDollors')"
                            />
                            <span matPrefix>$</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uSalesSellerAgentComissionInDollors.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Buyer Agent Comission
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.buyerAgentCommissionInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-6 col-sm-6 col-6 pr-2">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value(%)"
                              formControlName="uSalesBuyerAgentCommissionPercent"
                              (blur)="onInputBlur('uSalesBuyerAgentCommissionPercent')"
                              (keydown.enter)="onInputEnter('uSalesBuyerAgentCommissionPercent')"
                              (keydown)="validateNumber($event)"
                            />
                            <span matSuffix>%</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uSalesBuyerAgentCommissionPercent.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6 pl-2">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value($)"
                              formControlName="uSalesBuyerAgentComissionInDollors"
                              currencyMask
                              (blur)="onInputBlur('uSalesBuyerAgentComissionInDollors')"
                              (keydown.enter)="onInputEnter('uSalesBuyerAgentComissionInDollors')"
                            />
                            <span matPrefix>$</span>
                          </mat-form-field>
                          <mat-error *ngIf="f?.uSalesBuyerAgentComissionInDollors.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>

              <div class="row">
                <div class="col-md-12 col-sm-12 col-12">
                  <div class="section-middle">
                    <div class="section-data mt-0">
                      <div class="table-data mt-4">
                        <table class="data-table table-header" *ngIf="!navigatedFromMyPropertyPage">
                          <tr>
                            <td></td>
                            <td
                              [class.expanded-col]="!dealAnalysisSheetFacade.defaultYearList.includes(year)"
                              *ngFor="let year of dealAnalysisSheetFacade.displayYearList$ | async"
                            >
                              <span
                                class="btn-year btn-expand"
                                [class.shrink]="isExpanded10"
                                *ngIf="year == 10 || year == 6"
                                (click)="toggleYear(10, !isExpanded10)"
                              >
                                <mat-icon>arrow_left</mat-icon>
                                <mat-icon>arrow_right</mat-icon>
                              </span>
                              <span
                                class="btn-year btn-expand"
                                [class.shrink]="isExpanded15"
                                *ngIf="year == 15 || year == 11"
                                (click)="toggleYear(15, !isExpanded15)"
                              >
                                <mat-icon>arrow_left</mat-icon>
                                <mat-icon>arrow_right</mat-icon>
                              </span>
                              <span
                                class="btn-year btn-expand"
                                [class.shrink]="isExpanded20"
                                *ngIf="year == 20 || year == 16"
                                (click)="toggleYear(20, !isExpanded20)"
                              >
                                <mat-icon>arrow_left</mat-icon>
                                <mat-icon>arrow_right</mat-icon>
                              </span>
                              <span
                                class="btn-year btn-expand"
                                [class.shrink]="isExpanded30"
                                *ngIf="year == 30 || year == 21"
                                (click)="toggleYear(30, !isExpanded30)"
                              >
                                <mat-icon>arrow_left</mat-icon>
                                <mat-icon>arrow_right</mat-icon>
                              </span>
                              Year {{ year }}
                            </td>
                          </tr>
                        </table>

                        <table class="data-table table-header" *ngIf="navigatedFromMyPropertyPage">
                          <tr>
                            <td></td>

                            <td
                              [ngClass]="{ currentYear: currentYear === year }"
                              *ngFor="let year of computedDealAnalysisResult.yearList"
                            >
                              <span> {{ year }}</span>
                            </td>
                          </tr>
                        </table>

                        <mat-accordion class="compute-table" [multi]="true">
                          <div class="compute-section">
                            <mat-expansion-panel>
                              <mat-expansion-panel-header>
                                <mat-panel-title> Revenue </mat-panel-title>
                              </mat-expansion-panel-header>
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.grossRentInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Gross Rent
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.grossRent">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.monthlyRentInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Monthly Rent
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.monthlyRent">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.vacancyInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Vacancy
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.vacancy">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                              </table>
                            </mat-expansion-panel>
                            <div class="group-footer">
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.operatingIncomeInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Operating Income
                                    </td>
                                    <td
                                      [class.text-danger]="isNumber(data) && data < 0"
                                      *ngFor="let data of computedDealAnalysisResult.operatingIncome"
                                    >
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                              </table>
                            </div>
                          </div>
                          <div class="compute-section">
                            <mat-expansion-panel>
                              <mat-expansion-panel-header>
                                <mat-panel-title> Expenses </mat-panel-title>
                              </mat-expansion-panel-header>
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.propertyTaxesInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Property Taxes
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.propertyTax">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr *ngIf="isAdvanceMode">
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.insuranceInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Insurance
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.insurance">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr *ngIf="isAdvanceMode">
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.hoaFeeInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      HOA Fee
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.hoaFee">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr *ngIf="!isAdvanceMode">
                                  <ng-container>
                                    <td>Other Expenses</td>
                                    <td *ngFor="let data of computedDealAnalysisResult.otherExpenses">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr *ngIf="isAdvanceMode">
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.propertyManagementInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Property Management
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.propertyManagement">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr *ngIf="isAdvanceMode">
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.repairsAndMaintenanceInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Repairs & Maintenance
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.maintenance">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr *ngIf="isAdvanceMode">
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.warrantyServiceInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Warranty Service
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.warrantyService">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr *ngIf="isAdvanceMode">
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.accountingInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Accounting
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.accounting">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                              </table>
                            </mat-expansion-panel>
                            <div class="group-footer">
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="
                                          isAdvanceMode
                                            ? dealAnalysisConfig.tooltipText.operatingExpenseInput
                                            : dealAnalysisConfig.tooltipText.IsAdvanceOperatingExpenseInput
                                        "
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Operating Expenses
                                    </td>
                                    <td
                                      [class.text-danger]="isNumber(data) && data < 0"
                                      *ngFor="let data of computedDealAnalysisResult.operatingExpense"
                                    >
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                              </table>
                            </div>
                          </div>
                          <div class="compute-section">
                            <mat-expansion-panel>
                              <mat-expansion-panel-header>
                                <mat-panel-title> Cash Flow </mat-panel-title>
                              </mat-expansion-panel-header>
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.operatingIncomeInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Operating Income
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.operatingIncome">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.operatingExpenseInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Operating Expenses
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.operatingExpense">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.netOperatingIncomeInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Net Operating Income
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.netOperatingIncome">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>Mortgage Payment</td>
                                    <td *ngFor="let data of computedDealAnalysisResult.mortgagePayment">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                              </table>
                            </mat-expansion-panel>
                            <div class="group-footer">
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.cashFlowInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Cash Flow
                                    </td>
                                    <td
                                      [class.text-danger]="isNumber(data) && data < 0"
                                      *ngFor="let data of computedDealAnalysisResult.cashFlow"
                                    >
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                              </table>
                            </div>
                          </div>
                          <div class="compute-section">
                            <mat-expansion-panel>
                              <mat-expansion-panel-header>
                                <mat-panel-title> Tax Deductions </mat-panel-title>
                              </mat-expansion-panel-header>
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.operatingExpenseInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Operating Expenses
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.operatingExpense">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.mortgageInterestInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Mortgage Interest
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.mortgageInterest">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.depreciationInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Depreciation
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.depreciation">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                              </table>
                            </mat-expansion-panel>
                            <div class="group-footer">
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.taxDeductionInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Total Deductions
                                    </td>
                                    <td
                                      [class.text-danger]="isNumber(data) && data < 0"
                                      *ngFor="let data of computedDealAnalysisResult.totalDeduction"
                                    >
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.taxableIncome"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >Taxable Income
                                    </td>
                                    <td
                                      [class.text-danger]="isNumber(data) && data < 0"
                                      *ngFor="let data of computedDealAnalysisResult.taxableIncome"
                                    >
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                              </table>
                            </div>
                          </div>
                          <div class="compute-section">
                            <mat-expansion-panel>
                              <mat-expansion-panel-header>
                                <mat-panel-title> Equity Appreciation </mat-panel-title>
                              </mat-expansion-panel-header>
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.propertyValueInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Property Value
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.propertyValue">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>Mortgage Balance</td>
                                    <td *ngFor="let data of computedDealAnalysisResult.mortgageBalance">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                              </table>
                            </mat-expansion-panel>
                            <div class="group-footer">
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.yourEquityInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Your Equity
                                    </td>
                                    <td
                                      [class.text-danger]="isNumber(data) && data < 0"
                                      *ngFor="let data of computedDealAnalysisResult.equity"
                                    >
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                              </table>
                            </div>
                          </div>
                          <div class="compute-section">
                            <mat-expansion-panel>
                              <mat-expansion-panel-header>
                                <mat-panel-title> When Should I Sell? </mat-panel-title>
                              </mat-expansion-panel-header>
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.yourEquityInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Your Equity
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.equity">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.sellingCostsInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Selling Costs
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.sellingCost">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.cumulativeCashFlowInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Cumulative Cash Flow
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.cumulativeCashFlow">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.totalCashInvestedInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Total Cash Invested
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.totalCashInvested">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                              </table>
                            </mat-expansion-panel>
                            <div class="group-footer">
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.netProceedsInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Net Proceeds
                                    </td>
                                    <td
                                      [class.text-danger]="isNumber(data) && data < 0"
                                      *ngFor="let data of computedDealAnalysisResult.netProceed"
                                    >
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.profitOnSaleInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Profit On Sale
                                    </td>
                                    <td
                                      [class.text-danger]="isNumber(data) && data < 0"
                                      *ngFor="let data of computedDealAnalysisResult.profitOnSale"
                                    >
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                              </table>
                            </div>
                          </div>
                          <div class="compute-section">
                            <mat-expansion-panel>
                              <mat-expansion-panel-header>
                                <mat-panel-title> Return Indicators </mat-panel-title>
                              </mat-expansion-panel-header>
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.capRateOnPurchasePriceInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Cap Rate on Purchase Price
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.capRateOnPurchasePriceRatio">
                                      {{ !isNumber(data) ? '--' : (data * 100).toFixed(2) + '%' }}
                                    </td></ng-container
                                  >
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.capRateOnFMVInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Cap Rate on FMV
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.capRateOnFMVRatio">
                                      {{ !isNumber(data) ? '--' : (data * 100).toFixed(2) + '%' }}
                                    </td></ng-container
                                  >
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.cashOnCashReturnInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Cash on Cash Return
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.cashOnCashReturnRatio">
                                      {{ !isNumber(data) ? '--' : (data * 100).toFixed(2) + '%' }}
                                    </td></ng-container
                                  >
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.returnOnInvestmentInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Return on Investment
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.returnOnInvestmentRatio">
                                      {{ !isNumber(data) ? '--' : (data * 100).toFixed(2) + '%' }}
                                    </td></ng-container
                                  >
                                </tr>
                              </table>
                            </mat-expansion-panel>
                            <div class="group-footer">
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.returnOnEquityInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Return on Equity
                                    </td>
                                    <td
                                      [class.text-danger]="isNumber(data) && data < 0"
                                      *ngFor="let data of computedDealAnalysisResult.returnOnEquityRatio"
                                    >
                                      {{ !isNumber(data) ? '--' : (data * 100).toFixed(2) + '%' }}
                                    </td></ng-container
                                  >
                                </tr>
                              </table>
                            </div>
                          </div>
                        </mat-accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </mat-expansion-panel>
        </div>
      </div>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
