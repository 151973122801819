import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { SimpleDatePipe } from 'src/app/shared/pipe/simple-date.pipe';
import { SearchApi } from 'src/app/workspace/api/search.api';
import { SearchState } from 'src/app/workspace/state/search.state';
import { AuthorizeService } from 'src/app/_core/access-control/authorize.service';
import { PropertyFilterConfig } from '../config/property-filter.config';
import {
  CustomPropertyFilter,
  FilterDisplayConfig,
  FilterDisplayKeyConfig,
  PropertyFilter,
} from '../interface/property-filter.interface';
import { PropertyFilterState } from '../state/property-filter.state';

@Injectable({
  providedIn: 'root',
})
export class PropertyFilterService {
  loginShown: boolean = true;
  appliedFilterTemp: any;
  initFilter: any;
  urlParamsTemp: any;
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private appState: AppState,
    private propertyFilterState: PropertyFilterState,
    private decimalPipe: DecimalPipe,
    private simpleDatePipe: SimpleDatePipe,
    private activatedRoute: ActivatedRoute,
    private authorizeService: AuthorizeService,
    private searchApi: SearchApi,
    private searchState: SearchState // private searchService: SearchService
  ) {
    // Waiting for a second before setting the default filter to URL.
    // This is to ensure that the Route.snapshot is available.

    // setTimeout(() => {
    //   this._setInitialFilterToUrl();
    //   this._listenToAppliedFilters();
    // }, 1000);
    // this.initSubscription();

    this._router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.onRouteChange(event);
    });
  }

  private onRouteChange(event: NavigationEnd): void {
    if (event.urlAfterRedirects.split('/')[1] == 'workspace') {
      setTimeout(() => {
        this._setInitialFilterToUrl();
        this._listenToAppliedFilters();
      }, 1000);
      this.initSubscription();
    }
  }

  initSubscription() {
    // .pipe(debounceTime(1000))
    this.activatedRoute.queryParams.subscribe((params) => {
      const newParams = { ...params };
      if (params.regularSales == 'active' || params.regularSales == 'true') {
        delete newParams.regularSales;
        newParams.isForSale = true;
      } else if (params.regularSales == 'false') {
        delete newParams?.regularSales;
        newParams.isPreFcl = true;
        newParams.isAuction = true;
        newParams.isReo = true;
        newParams.isHud = true;
      }

      if (params.priceRange) {
        const values = params.priceRange?.split('-');
        const [minValue, maxValue] = values.map((val) => parseInt(val, 10));
        delete newParams.priceRange;
        newParams.lPrice = minValue;
        newParams.hPrice = maxValue;
      }
      this._router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: newParams,
        queryParamsHandling: 'merge',
      });
    });
  }

  /**
   * This will be called when a custom filter on the property filter bar is clicked.
   * It will remove any existing filters and apply the selected filter.
   */
  public setCustomFilterToUrl(filter: string) {
    const queryParams: Record<string, any> = JSON.parse(JSON.stringify(this._route.snapshot.queryParams));

    this.getAllFilterKeyList().forEach((key) => {
      queryParams[key] = null;
    });

    queryParams[filter] = true;

    this._router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  /**
   * Set applied filter to the URL as query parameters
   */
  public setPrimaryFilterToUrl(filter: Partial<PropertyFilter>, searchAddress?): void {
    const queryParams: Record<string, any> = JSON.parse(JSON.stringify(this._route.snapshot.queryParams));

    this.getAllFilterKeyList().forEach((key) => {
      queryParams[key] = null;
    });

    // const defaultFilter: Partial<PropertyFilter> = this.appState.companyInfoValue?.defaultPropertyFilter;

    // const applicableFilter: Record<string, any> = {};

    for (const key in filter) {
      // if (filter[key] !== defaultFilter[key]) {
      queryParams[key] = filter[key];
      // } else {
      //   applicableFilter[key] = null;
      // }
    }

    if (searchAddress) queryParams.search = searchAddress;
    // if (Object.keys(applicableFilter).length) {
    // this._router.navigate(['workspace'], {
    this._router.navigate(['workspace'], {
      queryParams,
      queryParamsHandling: 'merge',
    });

    setTimeout(() => {
      this.saveFilterList();
    }, 1500);
  }

  /**
   * Save selected filters
   */
  saveSelectedFilters(appliedFilters) {
    this.propertyFilterState.savedFiltersValue = appliedFilters;
  }

  saveFilterList() {
    delete this.searchState.searchAddressValue.id;
    const userLog = {
      ...this.searchState.searchAddressValue,
      filters: this.propertyFilterState.appliedFilter,
      type: 'filter',
    };
    this.searchApi.setSearch(userLog);
  }

  /**
   * Get primary filters from the URL
   */
  public getPrimaryFilterFromUrl(): Partial<PropertyFilter> {
    const queryParams: Record<string, any> = JSON.parse(JSON.stringify(this._route.snapshot.queryParams));
    const customFilterConfig: CustomPropertyFilter[] = this.appState.companyInfoValue.customPropertyFilterConfig;

    let appliedFilter: Partial<PropertyFilter> = {};
    // Set Primary filters for any custom filter applied, based on config.
    // This is of low priority and hence applied first.
    this.getCustomFilterKeyList().forEach((key) => {
      if (queryParams[key]) {
        const customFilter = customFilterConfig.find((config) => config.key === key);
        appliedFilter = { ...appliedFilter, ...customFilter.filters };
      }
    });
    // Set Primary filters for any primary filter applied
    // This is of high priority and hence applied last, overriding any custom filter.
    this.getPrimaryFilterKeyList().forEach((key) => {
      if (queryParams[key]) {
        appliedFilter[key] = queryParams[key];
      }
    });
    return appliedFilter;
  }

  /**
   * Toggle the filter sheet to open/close
   */
  public toggleFilterSheet(): void {
    this.propertyFilterState.isFilterSheetOpen = !this.propertyFilterState.isFilterSheetOpen;
  }

  /**
   * Returns the list of all primary-filter and custom-filter keys
   */
  public getAllFilterKeyList(): string[] {
    return [...this.getPrimaryFilterKeyList(), ...this.getCustomFilterKeyList()];
  }

  /**
   * Returns the list of all primary-filter keys
   */
  public getPrimaryFilterKeyList(): string[] {
    return PropertyFilterConfig.primaryFilterParamList;
  }

  /**
   * Returns the list of all custom-filter keys
   */
  public getCustomFilterKeyList(): string[] {
    return this.appState.companyInfoValue?.customPropertyFilterConfig.map((config) => config.key) || [];
  }

  /**
   * Returns the applied filters in a displayable format
   */
  public getFilterDisplayList(filter: Partial<PropertyFilter>): { key: string; value: string }[] {
    const displayList: { key: string; value: string }[] = [];

    PropertyFilterConfig.primaryFilterDisplayConfig.forEach((config) => {
      switch (config.type) {
        case 'VALUE':
          const displayValue = this._formatValueTypeFilter(config, filter);
          displayValue && displayList.push(displayValue);
          break;

        case 'RANGE':
          const displayRange = this._formatRangeTypeFilter(config, filter);
          displayRange && displayList.push(displayRange);
          break;
      }
    });

    return displayList;
  }

  private _formatValueTypeFilter(
    config: FilterDisplayConfig,
    filter: Partial<PropertyFilter>
  ): { key: string; value: string } {
    let displayValue: { key: string; value: string } | null = null;

    // let formattedValue;

    const validKeyConfigList = config.keys.filter((keyConfig) => {
      if (!filter || !(keyConfig.key in filter)) return false;

      if ('condition' in keyConfig) {
        if (keyConfig.condition === filter[keyConfig.key]) return true;
      } else return true;
    });

    let formattedValue: any[] = [];

    validKeyConfigList.forEach((keyConfig) => {
      if (Array.isArray(filter[keyConfig.key]) && filter[keyConfig.key].length) {
        formattedValue.push(
          filter[keyConfig.key]
            .map((value) => {
              value = this._formatFilterValue(keyConfig, value);
              if (typeof value == 'boolean') value = keyConfig.name;
              return value;
            })
            .join(', ')
        );
      } else if (!Array.isArray(filter[keyConfig.key])) {
        let value = this._formatFilterValue(keyConfig, filter[keyConfig.key]);

        if (typeof value == 'boolean') value = keyConfig.name;
        formattedValue.push(value);
      }
    });

    if (formattedValue.length) {
      displayValue = {
        key: config.name,
        value: formattedValue.join(', '),
      };
    }

    return displayValue;
  }

  private _formatRangeTypeFilter(
    config: FilterDisplayConfig,
    filter: Partial<PropertyFilter>
  ): { key: string; value: string } {
    if (!filter) return;

    let displayValue: { key: string; value: string } | null = null;

    const minKeyConfig = config.keys.find((keyConfig) => keyConfig.keyType == 'min');
    const maxKeyConfig = config.keys.find((keyConfig) => keyConfig.keyType == 'max');

    if (filter[minKeyConfig.key] && filter[maxKeyConfig.key]) {
      const minFormattedValue = this._formatFilterValue(minKeyConfig, filter[minKeyConfig.key]);
      const maxFormattedValue = this._formatFilterValue(maxKeyConfig, filter[maxKeyConfig.key]);

      displayValue = {
        key: config.name,
        value: `${minFormattedValue} - ${maxFormattedValue}`,
      };
    } else if (filter[minKeyConfig.key]) {
      const minFormattedValue = this._formatFilterValue(minKeyConfig, filter[minKeyConfig.key]);

      displayValue = {
        key: config.name,
        value: `${minFormattedValue} onwards`,
      };
    } else if (filter[maxKeyConfig.key]) {
      const maxFormattedValue = this._formatFilterValue(maxKeyConfig, filter[maxKeyConfig.key]);

      displayValue = {
        key: config.name,
        value: `Up to ${maxFormattedValue}`,
      };
    }

    return displayValue;
  }

  /**
   * Formats the filter value by adding prefix, suffix, thousands separator, enum conversion, etc.
   */
  private _formatFilterValue(config: FilterDisplayKeyConfig, value: any) {
    if ([null, undefined, ''].includes(value)) return;

    let formattedValue = value;

    if (config.enum) {
      formattedValue = PropertyFilterConfig[config.enum][formattedValue];
    }

    switch (config.dataType) {
      case 'number':
        formattedValue = this.decimalPipe.transform(value, '1.0-2');
        break;

      case 'date':
        formattedValue = this.simpleDatePipe.transform(value);
        break;

      case 'boolean':
        formattedValue = Boolean(value);
        break;
    }

    if (config.prefix) {
      formattedValue = `${config.prefix} ${formattedValue}`;
    }

    if (config.suffix) {
      formattedValue = `${formattedValue} ${config.suffix}`;
    }

    return formattedValue;
  }

  /**
   * @private
   * Sets the default filter to the URL on startup if no other filter is applied
   */
  private _setInitialFilterToUrl() {
    let queryParams: Record<string, any> = JSON.parse(JSON.stringify(this._route.snapshot.queryParams));

    // If any filter is already applied, then do not set the default filter
    if (this.getAllFilterKeyList().some((key) => queryParams[key])) {
      return;
    }

    if (this.propertyFilterState?.appliedFilter && Object.keys(this.propertyFilterState.appliedFilter).length > 0) {
      queryParams = { ...queryParams, ...this.propertyFilterState.appliedFilter };
      this._router.navigate([], {
        queryParams,
        queryParamsHandling: 'merge',
      });
      return;
    }

    // If no filter is applied, then set the default filter from companyInfo
    this.appState.companyInfo$.subscribe((res) => {
      if (!res) return;
      if (res?.defaultPropertyFilter) {
        const defaultFilter: Partial<PropertyFilter> = res.defaultPropertyFilter;
        queryParams = { ...queryParams, ...defaultFilter };
        this._router.navigate([], {
          queryParams,
          queryParamsHandling: 'merge',
        });
      }
    });
  }

  private _listenToAppliedFilters(): void {
    this._route.queryParams
      .pipe(
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        tap((params) => {
          // Create a deep copy of the params to avoid modifying the original query params
          const modifiedParams = JSON.parse(JSON.stringify(params));

          // Ensure homeType is always an array
          if (modifiedParams.homeType && !Array.isArray(modifiedParams.homeType)) {
            modifiedParams.homeType = [modifiedParams.homeType];
          }
          // Handle sortField and sortOrder
          if (modifiedParams.sortField && modifiedParams.sortOrder) {
            this.propertyFilterState.propertySortFields = {
              sortField: modifiedParams.sortField,
              sortOrder: modifiedParams.sortOrder,
            };
          }
        }),
        filter((params) => {
          return Object.keys(params).some((key) => this.getAllFilterKeyList().includes(key));
        }),
        tap((params) => {
          if (JSON.stringify(this.urlParamsTemp) == JSON.stringify(params)) {
            return;
          } else {
            this.urlParamsTemp = params;
          }

          const appliedFilter: Partial<PropertyFilter> = {};
          this.getAllFilterKeyList().forEach((key) => {
            if (params[key]) {
              if (key === 'homeType' && !Array.isArray(params[key])) {
                // Ensure homeType is always an array when adding to appliedFilter
                appliedFilter[key] = [params[key]]; // Convert homeType to array if it's a string
              } else {
                appliedFilter[key] = params[key];
              }
            }
          });

          const distressFilter = this.hasAnyKey(appliedFilter); // Call hasAnyKey once after the loop

          if (distressFilter) {
            if (!this.appState.authTokenValue?.idToken) {
              if (this.loginShown) {
                this.initFilter = JSON.parse(JSON.stringify(appliedFilter));
                this.appliedFilterTemp = JSON.parse(JSON.stringify(appliedFilter));
                setTimeout(() => {
                  this.loginShown = false;
                }, 1500);
              } else if (
                (this.appliedFilterTemp && JSON.stringify(appliedFilter) === JSON.stringify(this.appliedFilterTemp)) ||
                (this.initFilter && JSON.stringify(appliedFilter) === JSON.stringify(this.initFilter))
              ) {
                setTimeout(() => {
                  this.loginShown = false;
                }, 1500);
              } else if (!params?.login) {
                this.loginShown = true;
                this.authorizeService.openLogInDialog();
                this.appliedFilterTemp = JSON.parse(JSON.stringify(appliedFilter));
                setTimeout(() => {
                  this.loginShown = false;
                }, 1500);
                return;
              } else {
                this.appliedFilterTemp = JSON.parse(JSON.stringify(appliedFilter));
                return;
              }
            }
          } else {
            setTimeout(() => {
              this.loginShown = false;
            }, 1500);
            this.appliedFilterTemp = JSON.parse(JSON.stringify(appliedFilter));
          }
          this.propertyFilterState.appliedFilter = appliedFilter;
        })
      )
      .subscribe();
  }

  hasAnyKey(filters) {
    const keysToCheck = ['isPreFcl', 'isAuction', 'isReo', 'isHud', 'fcl'];
    for (const key of keysToCheck) {
      if (filters.hasOwnProperty(key)) {
        return true;
      }
    }
    return false;
  }
}
