import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgxGalleryAnimation, NgxGalleryComponent, NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { PropertyState } from 'src/app/workspace/state/property.state';
import constant from 'src/constant.json';
import { ActivityLoggerService } from '../../service/activity-logger.service';
import { ImageGalleryState } from '../../state/image-gallery.state';
import { UserState } from '../../state/user.state';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImageGalleryComponent implements OnInit, OnDestroy {
  @ViewChild('gallery') gallery!: NgxGalleryComponent;
  @Input() selectedIndex: number;
  @Input() isImagesFullView: boolean;
  @Output() close = new EventEmitter<void>();

  constructor(
    private imageGalleryState: ImageGalleryState,
    public appState: AppState,
    private userState: UserState,
    private activityLoggerService: ActivityLoggerService,
    private propertyState: PropertyState,
    private renderer: Renderer2
  ) { }

  onDestroyNotifier$ = new Subject();
  galleryImagesList: any[] = [];
  galleryOptions: NgxGalleryOptions[];
  isLoading: boolean = true;

  ngOnInit() {
    if (this.isImagesFullView) {
      this.galleryOptions = [
        {
          thumbnails: false,
          image: false,
          preview: true,
        },
      ];
    } else {
      this.galleryOptions = [
        {
          width: '600px',
          height: '400px',
          thumbnailsColumns: 4,
          arrowPrevIcon: 'fa fa-chevron-left',
          arrowNextIcon: 'fa fa-chevron-right',
          imageAnimation: NgxGalleryAnimation.Slide,
          imageArrows: true,
          thumbnailsArrows: true,
          imageDescription: true,
        },
        {
          breakpoint: 800,
          width: '100%',
          height: '600px',
          imagePercent: 80,
          thumbnailsPercent: 20,
          thumbnailsMargin: 20,
          thumbnailMargin: 20,
        },
        {
          breakpoint: 400,
          preview: false,
        },
      ];
    }
    this.imageGalleryState.imgGalleryList$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      this.galleryImagesList = [];
      let mediumHeight = 500;
      let smallHeight = 100;
      if (res && res.length) {
        res.forEach((img, index) => {
          this.galleryImagesList.push({
            small: `${img}&height=${smallHeight}`,
            medium: `${img}&height=${mediumHeight}`,
            big: `${img}`,
            description: `${index + 1}/${res.length}`,
          });
        });
        this.isLoading = false;
      } else {
        this.galleryImagesList = [];
        this.isLoading = false; // Ensure isLoading is set to false even if no images are found
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isImagesFullView) {
      if (changes['selectedIndex'] != changes['selectedIndex'].previousValue) {
        setTimeout(() => {
          this.openImageInGallery(changes['selectedIndex'].currentValue); // React to index changes
        }, 100);
      }
    }
  }

  ngOnDestroy(): void {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }

  ngAfterViewInit(): void {
    if (!this.gallery) {
      console.error('Gallery is not initialized properly.');
    }
    setTimeout(() => {
      if (this.gallery && this.gallery.images.length > 0 && this.isImagesFullView) {
        this.openImageInGallery(this.selectedIndex);
      }
    }, 100);

    const closeButton = document.querySelector('.ngx-gallery-close');
    if (closeButton) {
      this.renderer.listen(closeButton, 'click', () => {
        this.onGalleryClose();
      });
    }
  }

  leadScoreUpdate() {
    let address = {
      city: this.propertyState.activePropertyDetailsValue['basic'].City,
      state: this.propertyState.activePropertyDetailsValue['basic'].StateOrProvince,
      street: this.propertyState.activePropertyDetailsValue['basic'].StreetAddress,
      zip: this.propertyState.activePropertyDetailsValue['basic'].PostalCode,
    };

    if (this.appState.authTokenValue?.idToken && this.userState.userDetails?.roles?.includes('investor'))
      this.logUserActivity(
        constant.LOGGER.propertyDetails.category,
        constant.LOGGER.propertyDetails.action.viewPropertyImage.key,
        this.propertyState.activePropertyDetailsValue['basic'].PMXPropertyId,
        address
      );
  }

  logUserActivity(category, action, PMXPropertyId, address) {
    let loggerParams = {
      category: category,
      action: action,
    };
    let inputData = {
      PMXPropertyId: PMXPropertyId,
      address: address,
    };
    this.activityLoggerService.logActivity(loggerParams, inputData);
  }

  openImageInGallery(index: number): void {
    if (this.gallery) {
      this.gallery.openPreview(this.selectedIndex);
    } else {
      console.error('Gallery reference is undefined.');
    }
  }

  onGalleryClose() {
    this.close.emit();
  }
}
