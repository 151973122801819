import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import accessControlConfig from 'src/app/_core/access-control/access-control.json';
import { ActivateModalComponent } from 'src/app/_shared/component/activate-account/activate-modal.component';
import { SignupDialogComponent } from 'src/app/_shared/component/login-signup/signup-dialog.component';
import { UserState } from 'src/app/_shared/state/user.state';
import { AppState } from 'src/app/app.state';
import { LocalStorageService } from 'src/app/shared/service/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizeService {
  userScope: string;
  userRoleList: Array<string>;
  accessControlList = accessControlConfig;
  upgradeSnackbar: MatSnackBarRef<any>;
  loginMatDialog: any;

  public signupDialogRef: MatDialogRef<SignupDialogComponent> | null = null;

  constructor(
    private storage: LocalStorageService,
    private snackbar: MatSnackBar,
    private matDialog: MatDialog,
    private appState: AppState,
    private userState: UserState
  ) { }

  canView(feature: string) {
    this.userScope = this.storage.userScope || 'trial';
    this.userRoleList = this.storage.roles || ['guest'];
    const permissionList: Array<string> = this.getPermissions(feature);
    return permissionList.includes('view');
  }

  canAct(feature: string) {
    const idToken = this.appState.authTokenValue?.idToken;
    this.userScope = this.storage.userScope || 'trial';
    this.userRoleList = this.storage.roles || ['guest'];
    const permissionList: Array<string> = this.getPermissions(feature);

    let isPermitted = permissionList.includes('act');

    if (!isPermitted && this.userRoleList.includes('guest') && !this.appState.authTokenValue?.accessToken) {
      this.openLogInDialog();
    } else if (!isPermitted && this.userState.userDetails?.emailVerificationDate === null && idToken) {
      this.matDialog.open(ActivateModalComponent, {
        width: '385px',
        disableClose: true,
        hasBackdrop: true,
        data: {
          message: '',
          title: '',
        },
      });
    } else if (!isPermitted) this.openSnackbar();

    return isPermitted;
  }

  getPermissions(feature: string) {
    if (
      !this.userScope ||
      !this.userRoleList ||
      !this.userRoleList.length ||
      !this.accessControlList ||
      !this.accessControlList[feature]
    ) {
      return [];
    }
    let userPermissionList = [];
    for (const userRole of this.userRoleList) {
      if (this.accessControlList[feature][userRole] && this.accessControlList[feature][userRole][this.userScope]) {
        const permissionList = this.accessControlList[feature][userRole][this.userScope];
        userPermissionList = userPermissionList.concat(permissionList);
      }
    }
    return userPermissionList;
  }

  openSnackbar() {
    const upgradeMsg = 'Please upgrade your account to access this feature.';
    this.upgradeSnackbar = this.snackbar.open(upgradeMsg, 'Dismiss', {
      duration: 5000,
      panelClass: ['snackbar-warning'],
      verticalPosition: 'top',
    });
  }

  openLogInDialog() {

    if (this.signupDialogRef) {
      return;
    }

    this.signupDialogRef = this.matDialog.open(SignupDialogComponent, {
      width: '385px',
      disableClose: true,
      hasBackdrop: true,
    });

    this.signupDialogRef.afterClosed().subscribe(() => {
      this.signupDialogRef = null;
    });
  }

  closeLoginDialog() {
    this.loginMatDialog.close();
  }

}
