import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { OpportunityScannerApi } from 'src/app/_shared/api/opportunity-scanner.api';
import { OpportunityScannerState } from "./opportunity-scanner.state";

@Injectable({
  providedIn: 'root',
})
export class OpportunityScannerService {

  constructor(
    private opScanState: OpportunityScannerState,
    private opScanApi: OpportunityScannerApi,
  ) { }

  getNeighborhood(): Observable<{ isLoading?: boolean; data?: any }> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.opScanApi
        .getNeighborhood()
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            observer.next({ data: res?.data });
          },
          (err) => {
            observer.next({ data: null });
          }
        );
    });
  }

  getOpportunities(params) {
    this.opScanState.isLoadingOpportunities = true;

    this.opScanApi.getOpportunities(params).subscribe(
      (res: any) => {
        if (res && res.status && res.status === 'OK' && res.data) {
          this.opScanState.opportunityList = res.data && res.data.paginatedResults ? res.data.paginatedResults : [];
          this.opScanState.opportunityCount = res.data && res.data.totalRecords ? res.data.totalRecords : 0;
        } else {
          this.opScanState.opportunityList = [];
          this.opScanState.opportunityCount = 0;
        }
        this.opScanState.isLoadingOpportunities = false;
      },
      (err: any) => {
        console.log('getOpportunities err : ', err);
        this.opScanState.isLoadingOpportunities = false;
      }
    );
  }

  getZoningCodes(params): Observable<{ isLoading?: boolean; data?: any }> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.opScanApi
        .getZoningCodes(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            observer.next({ data: res?.data ? res?.data : { "zoningCodes": null } });
          },
          (err) => {
            observer.next({ data: null });
          }
        );
    });
  }

  getZoningLimits(params): Observable<{ isLoading?: boolean; data?: any }> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.opScanApi
        .getZoningLimits(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            observer.next({ data: res?.data ? res?.data : { "zoningLimits": null } });
          },
          (err) => {
            observer.next({ data: null });
          }
        );
    });
  }

  calculateROI(params): Observable<{ isLoading?: boolean; data?: any }> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.opScanApi
        .calculateROI(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            observer.next({ data: res?.data });
          },
          (err) => {
            observer.next({ data: null });
          }
        );
    });
  }

  fetchComparables(params): Observable<{ isLoading?: boolean; data?: any }> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.opScanApi
        .fetchComparables(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            observer.next({ data: res?.data });
          },
          (err) => {
            observer.next({ data: null });
          }
        );
    });
  }

  saveOpportunity(params): Observable<{ isLoading?: boolean; data?: any }> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.opScanApi
        .saveOpportunity(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            observer.next({ data: res?.data });
          },
          (err) => {
            observer.next({ data: null });
          }
        );
    });
  }

  downloadRoiAnalysisReport(params, name): Observable<{ isLoading?: boolean; data?: any }> {

    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.opScanApi
        .downloadRoiAnalysisReport(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            var downloadURL = URL.createObjectURL(res);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = name + '.pdf';
            link.click();

            observer.next({ data: res });
          },
          (err) => {
            observer.next({ data: null });
          }
        );
    });

  }

  addOpportunity(params): Observable<{ isLoading?: boolean; data?: any }> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.opScanApi
        .addOpportunity(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            observer.next({ data: res?.data });
          },
          (err) => {
            observer.next({ data: null });
          }
        );
    });
  }
}