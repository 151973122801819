import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OpportunityScannerState {

  private isLoadingOpportunities$ = new BehaviorSubject<boolean>(false);
  isLoadingOpportunitiesValue = this.isLoadingOpportunities$.asObservable();

  public get isLoadingOpportunities(): boolean {
    return this.isLoadingOpportunities$.value;
  }
  public set isLoadingOpportunities(status: boolean) {
    this.isLoadingOpportunities$.next(status);
  }

  private opportunityList$ = new BehaviorSubject<Array<any>>([]);
  opportunityListValue = this.opportunityList$.asObservable();
  public get opportunityList(): Array<any> {
    return this.opportunityList$.value;
  }
  public set opportunityList(recommendedData: Array<any>) {
    this.opportunityList$.next(recommendedData);
  }

  private opportunityCount$ = new BehaviorSubject<number>(0);
  opportunityCountValue = this.opportunityCount$.asObservable();
  public get opportunityCount(): number {
    return this.opportunityCount$.value;
  }
  public set opportunityCount(count: number) {
    this.opportunityCount$.next(count);
  }

  private comparableList$ = new BehaviorSubject<Array<any>>([]);
  comparableListValue = this.comparableList$.asObservable();
  public get comparableList(): Array<any> {
    return this.comparableList$.value;
  }
  public set comparableList(recommendedData: Array<any>) {
    this.comparableList$.next(recommendedData);
  }

  private createdOpportunity$ = new BehaviorSubject<object>(null);
  createdOpportunityValue = this.createdOpportunity$.asObservable();
  public get createdOpportunity(): object {
    return this.createdOpportunity$.value;
  }
  public set createdOpportunity(recommendedData: object) {
    this.createdOpportunity$.next(recommendedData);
  }
}