export const environment = {
  env: 'qa',
  production: false,
  baseUrl: 'qa.prospektr.ai',
  prospektrBackendBaseURL: 'https://qa-prospectorapi.propmix.io/',
  mediaUrl: 'https://media.propmix.io/mlslite/media/GetImageURL?fromApp=',
  trialcompanyCode: '12FY85T',
  defaultSignUp: 'https://qa.prospektr.ai/signup/',
  company: {
    tabrasa: {
      name: 'Tabrasa',
      companyCode: 'WTDAXUV',
    },
    prospektr: {
      name: 'Prospektr',
      companyCode: '12FY85T',
    },
  },
  s3Upload: {
    project: 'PROSPECTOR',
    env: 'QA',
    type: 'base64',
  },
  defaultCompany: 'app',
  propmixBaseURL: 'https://api.propmix.io/',
  listingImageBaseUrl: 'https://qa-media.propmix.io/mktlite/media/image',
  weatherTemperatureApiKey: 'eb0152fe0e2b4386b9762939242310',
  weatherTemperatureApiUrl: 'https://api.weatherapi.com/v1/current.json',
};
