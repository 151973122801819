import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PublicRecordApi } from 'src/app/_shared/api/public-record.api';
import { ActivateModalComponent } from 'src/app/_shared/component/activate-account/activate-modal.component';
import { SignupDialogComponent } from 'src/app/_shared/component/login-signup/signup-dialog.component';
import { UserState } from 'src/app/_shared/state/user.state';
import { AppState } from 'src/app/app.state';
import { AuthToken } from '../../interface/auth.interface';
import { CookieStorageService } from '../cookie-storage.service';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  /**
   * Flag to check if the error is already active.
   * It will stop multiple error popups for subsequent request failures.
   */
  private isActiveError: boolean = false;

  // private propmixAccessToken = environment.propmixAccessToken;
  private propmixAccessToken: string;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private snackbar: MatSnackBar,
    private cookieStorageService: CookieStorageService,
    private appState: AppState,
    private userState: UserState,
    private publicRecordApi: PublicRecordApi
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqHeader = { Accept: 'application/json' };

    if (request?.url.includes('mls/v3/GetMLSBoards')) {
      this.publicRecordApi.getToken().subscribe((res) => {
        if (res && res.data && res.data.token) {
          this.propmixAccessToken = res.data.token;
        }
      });
      reqHeader['access-token'] = this.propmixAccessToken;
    } else {
      const idToken = this.cookieStorageService.getCookieValue('idToken');
      const accessToken = this.cookieStorageService.getCookieValue('accessToken');
      const refreshToken = this.cookieStorageService.getCookieValue('refreshToken');

      if (idToken) reqHeader['id-token'] = idToken;
      if (accessToken) reqHeader['access-token'] = accessToken;
      if (refreshToken) reqHeader['refresh-token'] = refreshToken;
    }

    const modifiedReq: HttpRequest<any> = request.clone({ headers: new HttpHeaders(reqHeader) });

    return next.handle(modifiedReq).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (!(event instanceof HttpResponse)) return;

          const authToken: Partial<AuthToken> = {};
          const idToken = event.headers.get('id-token');
          const accessToken = event.headers.get('access-token');
          const refreshToken = event.headers.get('refresh-token');

          if (idToken) {
            authToken.idToken = idToken;
            this.cookieStorageService.setCookie('idToken', idToken, 7);
          }
          if (accessToken) {
            authToken.accessToken = accessToken;
            this.cookieStorageService.setCookie('accessToken', accessToken, 7);
          }
          if (refreshToken) {
            authToken.refreshToken = refreshToken;
            this.cookieStorageService.setCookie('refreshToken', refreshToken, 7);
          }

          if (Object.keys(authToken).length) this.appState.authTokenValue = authToken;

          return event;
        },
        ({ error }: HttpErrorResponse) => {
          /** If any dialog-box is open, ignore subsequent errors */
          if (this.isActiveError) return;

          /** If user's email is not verified, show Account Not Verified dialog */
          if (error?.status == 'UNVERIFIED') {
            this.isActiveError = true;
            sessionStorage.setItem('redirectUrl', this.router.url);

            const dialogRef = this.dialog.open(ActivateModalComponent, {
              width: '385px',
              disableClose: true,
              hasBackdrop: true,
            });
            dialogRef.afterClosed().subscribe((res) => {
              this.isActiveError = false;
            });

            return;
          }

          /** If user is not identified, clear all auth tokens */
          if (error?.statusCode == 401) this.appState.authTokenValue = null;

          /** If user is not identified, authenticated or authorized, show Sign-in dialog */
          if ([401, 403].includes(error?.statusCode)) {
            this.isActiveError = true;
            this.userState.userDetails = null;
            sessionStorage.setItem('redirectUrl', this.router.url);
            const dialogRef = this.dialog.open(SignupDialogComponent, {
              width: '385px',
              disableClose: true,
              hasBackdrop: true,
            });
            dialogRef.afterClosed().subscribe(() => {
              this.isActiveError = false;
            });

            return;
          }

          if (error?.statusCode == 500)
            this.snackbar.open(error?.message ? error.message : 'Something went wrong. Please try again later.', '', {
              duration: 4000,
              panelClass: ['snackbar-warning'],
              verticalPosition: 'top',
            });
        }
      )
    );
  }
}
